
import { computed, defineAsyncComponent, defineComponent } from 'vue'
import { QDrawer, QItem, QItemLabel, QItemSection, QNoSsr, QSeparator } from 'quasar'
import { storeToRefs } from 'pinia'
import useRoutes from 'src/compositions/routes'
import LuggageButton from 'src/components/advanced/luggage-button.vue'
import LanguageSelector from 'src/components/advanced/language-selector.vue'
import AuthorizationButton from 'src/components/advanced/authorization-button.vue'
import IconImage from 'src/components/simple/icon-image.vue'
import { useI18n$ } from 'boot/i18n'
import useDrawer from 'src/compositions/drawer'
import useUserDetails from 'stores/userDetails'
import PageLinks from 'components/simple/page/page-links.vue'
import LocationSelect from 'components/advanced/location-select.vue'
import ServiceSelector from 'components/advanced/service-selector.vue'

const name = 'page-drawer'

const anonymousPages = [
  'blog',
  'reviews',
  'contact'
]

export default defineComponent({
  name,
  components: {
    ServiceSelector,
    QNoSsr,
    QDrawer,
    QItem,
    QItemSection,
    QItemLabel,
    QSeparator,
    LuggageButton,
    LanguageSelector,
    AuthorizationButton,
    IconImage,
    PageLinks,
    LocationSelect,
    AdminBtn: defineAsyncComponent(() => import(/* webpackChunkName: "admin-btn", webpackMode: "lazy" */'components/simple/page/admin-btn.vue')),
  },
  props: {},
  setup() {
    const { isPartner, isAdmin, isSecondaryAdmin, isReferral } = storeToRefs(useUserDetails())
    const { toPage, toRoot } = useRoutes()
    const { tp } = useI18n$('global.pages')
    const { opened, toggle } = useDrawer()

    const pages = computed(() => {
      let result = [...anonymousPages]
      if (isAdmin.value) {
        return result
      }
      if (!isPartner.value) {
        result.push('partners')
      }
      if (!isReferral.value) {
        result.push('referrals')
      }
      return result
    })

    return {
      isPartner,
      isAdmin,
      isSecondaryAdmin,
      pages,
      opened,
      tp,
      toRoot,
      toPage,
      toggle
    }
  }
})
