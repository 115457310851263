import { boot } from 'quasar/wrappers'
import { Composer, createI18n, useI18n as useVueI18n } from 'vue-i18n'

import { computed, InjectionKey, Ref, watch } from 'vue'
import { Language, LanguageContainer, Languages } from 'src/model/language.model'
import { NamedValue } from '@intlify/core-base'
import { useRouter } from 'vue-router'
import { Quasar, QuasarLanguage, StringDictionary } from 'quasar'
import { QSsrContext } from '@quasar/app-webpack'
import { ALLOWED_LANGUAGES, DEFAULT_COUNTRY_KEY } from 'src/model/constants'
import { inject } from 'src/compositions/common'
import useUserDetails from 'stores/userDetails'
import { extractLanguage } from 'src/functions/utils'
import { refStore } from 'stores/__common'
import { getDeviceLanguage } from 'src/functions/bridge'
import { ERROR_404 } from 'pages/names'
import useUserPreview from 'stores/userPreview'

export type UseI18n = ReturnType<typeof useI18n_>

const languageToIso: StringDictionary<Language> = {
  'en': 'en-US',
  'ru': 'ru',
  'it': 'it',
  'es': 'es-MX',
  'de': 'de',
  'fr': 'fr',
  'zh': 'zh-CN',
  'ja': 'ja',
  'ko': 'ko-KR',
  'tr': 'tr',
  'pt': 'pt-BR',
}

export const languageToLabel: StringDictionary<Language> = {
  'en': 'English',
  'ru': 'Русский',
  'de': 'Deutsch',
  'es': 'Español',
  'fr': 'Français',
  'it': 'Italiano',
  'zh': '中文',
  'ja': '日本語',
  'ko': '한국어',
  'tr': 'Türk',
  'pt': 'Português'
}

const customLocalizations = ['es-ES', 'pt-PT']

const i18nKeyString = 'qeepl_i18n'
const i18nKey: InjectionKey<UseI18n> = Symbol(i18nKeyString)

function computePlural(count: number, language: Language) {
  if (language === 'ru') {
    if (10 <= count % 100 && count % 100 <= 20) {
      return 2
    }
    if (count % 10 === 1) {
      return 0
    }
    if (2 <= count % 10 && count % 10 <= 4) {
      return 1
    }
    return 2
  }
  return count > 1 ? 2 : 1
}

export default boot(async ({ app, store, urlPath, ssrContext, redirect, router }) => {
  const { setLanguage: setUserLanguage, user } = refStore(useUserDetails(store))
  const { country } = refStore(useUserPreview(store))
  const deviceLanguage = ssrContext ? undefined : await getDeviceLanguage()
  const urlLanguage = extractLanguage(urlPath)
  const language = user.value?.langKey || deviceLanguage || urlLanguage || Language.DEFAULT

  const i18n = createI18n({
    globalInjection: true,
    legacy: false,
    locale: language,
    fallbackLocale: Language.DEFAULT,
    messages: {},
  })
  setUserLanguage(language)

  await loadAndSetTranslations(i18n.global as Composer, country.value?.key || DEFAULT_COUNTRY_KEY, language, ssrContext)

  // Set i18n instance on app
  app.use(i18n)

  if (urlLanguage && language !== urlLanguage) {
    redirect(urlPath.replace(`/${ urlLanguage }`, `/${ language }`))
  }

  if (!ssrContext) {
    router.beforeEach(async (to, from, next) => {
      if (ALLOWED_LANGUAGES.includes(to.params.language as Language) || to.name === ERROR_404) {
        next()
      } else {
        next({ name: ERROR_404 })
      }
    })
  }

  watch(() => country.value, async () => {
    await loadAndSetTranslations(i18n.global as Composer, country.value?.key || DEFAULT_COUNTRY_KEY, language, ssrContext)
  })
})

async function loadAndSetTranslations(i18n: Composer, country: string, language: Language, ssrContext?: QSsrContext | null) {
  const combinedLocalization = `${ language }-${ country }`
  const loadLanguage = customLocalizations.includes(combinedLocalization) ? combinedLocalization : language
  const translation = await import(/* webpackChunkName: "translation-[request]" */`src/i18n/${ loadLanguage }`)
    .then(module => module.default)
    .catch(() => {
      console.warn(`No translation module found for the the language=${ loadLanguage }`)
      return {}
    })
  i18n.setLocaleMessage(language, translation)
  Quasar.lang.set(translation as unknown as QuasarLanguage, ssrContext)
}

function createKey(key: string, prefix?: string): string {
  return prefix ? `${ prefix }.${ key }` : key
}

function useI18n_() {
  const { replace, currentRoute } = useRouter()
  //@ts-ignore
  const i18n = useVueI18n({ useScope: 'global' }) as Composer
  const { setLanguage: setUserLanguage } = useUserDetails()

  const allowedLanguages = ALLOWED_LANGUAGES

  const isoLanguage = computed(() => {
    const { country } = useUserPreview()
    const combinedLocalization = `${ i18n.locale.value as Language }-${ country }`
    if (customLocalizations.includes(combinedLocalization)) {
      return combinedLocalization
    } else {
      return languageToIso[i18n.locale.value as Language] as string
    }
  })

  const tp = (key: string, named: NamedValue = {}) => {
    return i18n.t(key, named)
  }

  const tc = (key: string, named: NamedValue, count: number) => {
    return i18n.t(key, named, computePlural(count, i18n.locale.value as Language))
  }

  const t = (key: string, named: NamedValue = {}, count?: number) => {
    return count ? tc(key, named, count) : i18n.t(key, named)
  }

  const setLanguage = (language: Language) => doSetLanguage(language)

  const doSetLanguage = (language: Language): Promise<void> => {
    const { country } = useUserPreview()
    if (ALLOWED_LANGUAGES.includes(language)) {
      return loadAndSetTranslations(i18n, country?.key ?? DEFAULT_COUNTRY_KEY, language)
        .then(() => {
          i18n.locale.value = language
          setUserLanguage(language)
        })
        .then(() => {
          const route = currentRoute.value
          return replace({ name: route.name!, params: { ...route.params, ...{ language } }, query: route.query })
        })
        .then(() => {
        })
    }
    return Promise.resolve()
  }

  const allowedExcluding = (language: Language) => ALLOWED_LANGUAGES.filter(l => l !== language)

  const allExcluding = (language: Language) => Languages.filter(l => l !== language)

  const unwrapLanguageContainer = <T>(lc: LanguageContainer<T>, language: Language = i18n.locale.value as Language): T | undefined => lc[language]

  const unwrapLanguageContainerSave = <T>(lc: LanguageContainer<T>, language: Language = i18n.locale.value as Language): T => lc[language] || lc[Language.DEFAULT]

  const wrapLanguageContainer = <T>(value: T, lc?: LanguageContainer<T>, language: Language = i18n.locale.value as Language, keepIfExists: boolean = false,): LanguageContainer<T> => {
    const result: LanguageContainer<T> = { ...lc } as LanguageContainer<T>
    const existing = result[language || Language.DEFAULT]
    if (!(existing && keepIfExists)) {
      result[language || Language.DEFAULT] = value
    }
    return result
  }

  return {
    language: i18n.locale as Ref<Language>,
    isoLanguage,
    allowedLanguages,
    t,
    tp,
    tc,
    getLocaleMessage: i18n.getLocaleMessage,
    setLanguage,
    allowedExcluding,
    allExcluding,
    st: unwrapLanguageContainerSave,
    ste: unwrapLanguageContainer,
    wst: wrapLanguageContainer,
  }
}

export function useI18n(): UseI18n {
  return inject(i18nKey, () => useI18n_())
}

function useI18n$_(prefix: string) {
  const instance = inject(i18nKey, () => useI18n_())
  const tp = (key: string, named: NamedValue = {}) => {
    return instance.t(createKey(key, prefix), named)
  }
  const tc = (key: string, named: NamedValue, count: number) => {
    return instance.t(createKey(key, prefix), named, count)
  }
  return { ...instance, tp, tc }
}

export function useI18n$(prefix: string): UseI18n {
  const key: InjectionKey<UseI18n> = Symbol(`${ i18nKeyString }/${ prefix }`)
  return inject(key, () => useI18n$_(prefix))
}
