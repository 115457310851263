
import { computed, defineComponent, onMounted, ref } from 'vue'
import { QItem, QItemLabel, QItemSection, QSelect } from 'quasar'
import { RouteLocationRaw, useRouter } from 'vue-router'
import { useI18n$ } from 'boot/i18n'
import { ServiceTypeAlias, ServiceTypeAliases } from 'src/model/common.model'
import useRoutes from 'src/compositions/routes'
import { ATTRACTION_ALIAS, ATTRACTION_SELF, CITY_ALIAS, CITY_SELF, INDEX_ALIAS, INDEX_SELF } from 'pages/names'
import { pathToKey } from 'src/functions/utils'

const name = 'service-selector'

export default defineComponent({
  name,
  components: {
    QSelect,
    QItem,
    QItemSection,
    QItemLabel
  },
  setup(props) {
    const { currentRoute } = useRouter()
    const {
      toRoot,
      toRootSelf,
      toRootAlias
    } = useRoutes()
    const {
      t,
      tp
    } = useI18n$(name)

    const serviceType = ref<ServiceTypeAlias>('LUGGAGE')

    onMounted(() => {
      serviceType.value = getRouteAlias()
    })

    const options = computed(() => {
      return ServiceTypeAliases.map((type) => ({
        label: t(`serviceTypeAlias.${ type }`),
        value: type
      }))
    })

    const getRouteAlias = (): ServiceTypeAlias => {
      const routeName = currentRoute.value.name
      if (routeName === INDEX_SELF || routeName === CITY_SELF || routeName === ATTRACTION_SELF) return 'SELF'
      else if (routeName === INDEX_ALIAS || routeName === CITY_ALIAS || routeName === ATTRACTION_ALIAS) return pathToKey(currentRoute.value.params.serviceTypeAlias as string) as ServiceTypeAlias
      else return 'LUGGAGE'
    }

    const getPathAlias = (serviceTypeAlias: ServiceTypeAlias): string => {
      if (serviceTypeAlias === 'LUGGAGE') return toRoot()
      else if (serviceTypeAlias === 'SELF') return toRootSelf()
      else return toRootAlias(serviceTypeAlias)
    }

    const toPage = (newServiceType: ServiceTypeAlias): RouteLocationRaw | undefined => {
      if (newServiceType !== serviceType.value) {
        return {
          path: getPathAlias(newServiceType),
          replace: true
        }
      }
    }

    return {
      t,
      tp,
      serviceType,
      options,
      toPage
    }
  }
})
