
import { computed, defineComponent, PropType } from 'vue'
import { QBtn } from 'quasar'
import { useI18n$ } from 'boot/i18n'
import useRoutes from 'src/compositions/routes'
import useUserPreviewExtension from 'src/compositions/userPreviewExtension'
import { aliasToServiceType } from 'src/functions/utils'
import { Container, ServiceTypeAlias } from 'src/model/common.model'
import { CityProjection } from 'src/model/city.model'

const name = 'countries-list'

export default defineComponent({
  name,
  components: {
    QBtn
  },
  props: {
    name: {
      type: String,
      required: true
    },
    alias: {
      type: String as PropType<ServiceTypeAlias | 'ALL'>,
      required: true
    },
    withPriority: {
      type: Boolean,
      required: false
    },
  },
  setup(props) {
    const { st, t, language } = useI18n$(props.name)
    const {
      toCityLuggage,
      toCitySelf,
      toCityAlias,
      toRootLuggage,
    } = useRoutes()
    const {
      countries,
      countryCities
    } = useUserPreviewExtension()

    const type = props.alias === 'ALL' ? 'ALL' : aliasToServiceType(props.alias as ServiceTypeAlias)

    const sortedCountries = computed(() => {
      const lang = language.value
      return countries.value.sort((a, b) => {
        const a1 = a.name[lang] || a.name.en || ''
        const b1 = b.name[lang] || b.name.en || ''
        return a1.localeCompare(b1)
      })
    })
    const filteredCountryCities = computed(() => {
      let alias: ServiceTypeAlias
      switch (props.alias) {
        case 'ALL':
        case 'LUGGAGE':
          alias = 'LUGGAGE'
          break
        case 'SELF':
          alias = 'SELF'
          break
        default:
          alias = props.alias
      }
      const result: Container<CityProjection[]> = {}
      const countryCitiesValue = countryCities.value
      const lang = language.value
      if (props.withPriority) {
        Object.keys(countryCitiesValue).forEach(key => {
          if (countryCitiesValue[key].length) {
            result[key] = countryCitiesValue[key].filter(c => c.services.includes(alias) && c.name[lang] && (c.priority! <= 5))
          }
        })
      } else {
        Object.keys(countryCitiesValue).forEach(key => {
          if (countryCitiesValue[key].length) {
            result[key] = countryCitiesValue[key].filter(c => c.services.includes(alias) && c.name[lang])
          }
        })
      }
      return result
    })
    const toCity = computed(() => {
      switch (props.alias) {
        case 'SELF':
          return toCitySelf
        case 'LUGGAGE':
        case 'ALL':
          return toCityLuggage
        default:
          return (city: CityProjection | string) => {
            return toCityAlias(props.alias as ServiceTypeAlias, city)
          }
      }
    })

    return {
      name,
      type,
      countries: sortedCountries,
      countryCities: filteredCountryCities,
      st,
      t,
      toRootLuggage,
      toCity
    }
  }
})
