
import { computed, defineComponent } from 'vue'
import { useI18n } from 'boot/i18n'
import { QBtn, QItem, QItemLabel, QItemSection, QNoSsr, useQuasar } from 'quasar'
import useRoutes from 'src/compositions/routes'
import useUserDetails from 'stores/userDetails'
import { refStore } from 'stores/__common'
import { useRouter } from 'vue-router'
import { PARTNERS, REFERRALS } from 'pages/names'
import {
  ROLE_MASTER_USER_OF_COMPANY,
  ROLE_ONBOARDING_USER_OF_COMPANY,
  ROLE_REFERRAL_CLIENT,
  ROLE_REFERRAL_PARTNER,
  ROLE_USER,
  ROLE_USER_OF_COMPANY
} from 'src/model/constants'

const name = 'page-links'

export default defineComponent({
  name,
  components: {
    QNoSsr,
    QItem,
    QItemSection,
    QItemLabel,
    QBtn
  },
  props: {
    btnClass: {
      type: String,
      default: ''
    },
  },
  emits: ['click'],
  setup() {
    const {
      isUser,
      isPartner,
      isAnonymous,
      isOnboardingUser,
      isReferral,
      user
    } = refStore(useUserDetails())
    const { toMyOrders, toPartners, toMyBusiness, toOnboarding, toReferralCabinet } = useRoutes()
    const { currentRoute } = useRouter()
    const { screen } = useQuasar()
    const { t } = useI18n()

    const pages = computed(() => {
      const rolePages = user.value.authorities
        .filter(a => !a.startsWith('ROLE_ADMIN'))
        .map(a => {
          switch (a) {
            case ROLE_USER:
              return { label: t('global.pages.orders'), to: toMyOrders() }
            case ROLE_ONBOARDING_USER_OF_COMPANY:
              return { label: t('action.continueOnboarding'), to: toOnboarding() }
            case ROLE_MASTER_USER_OF_COMPANY:
              return null
            case ROLE_USER_OF_COMPANY:
              return { label: t('global.pages.cabinet'), to: toMyBusiness() }
            case ROLE_REFERRAL_CLIENT:
            case ROLE_REFERRAL_PARTNER:
              return { label: t('global.pages.referralCabinet'), to: toReferralCabinet() }
            default:
              return null
          }
        })
        .filter(r => !!r)
      if (isAnonymous.value) {
        const currentRouteName = currentRoute.value.name as string
        if (currentRouteName !== PARTNERS) {
          return [...rolePages, { label: t('partners.joinUs'), to: toPartners() }]
        }
        if (currentRouteName !== REFERRALS) {
          return [...rolePages, { label: t('global.pages.referrals'), to: toReferralCabinet() }]
        }
      }
      return rolePages
    })

    const show = computed(() => {
      return isPartner.value || isReferral.value || isOnboardingUser.value || isUser.value
        || (isAnonymous.value && screen.gt.xs && currentRoute.value.name !== PARTNERS)
        || (isAnonymous.value && screen.gt.xs && currentRoute.value.name !== REFERRALS)
    })

    return {
      show,
      pages
    }
  }
})
