export const INDEX: string = 'index'
export const INDEX_LUGGAGE: string = 'index-luggage'
export const INDEX_SELF: string = 'index-self'
export const INDEX_ALIAS: string = 'index-alias'
export const CITY_LUGGAGE: string = 'city-luggage'
export const CITY_SELF: string = 'city-self'
export const CITY_ALIAS: string = 'city-alias'
export const ATTRACTION_LUGGAGE: string = 'attraction-luggage'
export const ATTRACTION_SELF: string = 'attraction-self'
export const ATTRACTION_ALIAS: string = 'attraction-alias'
export const BLOG_LIST: string = 'blog-list'
export const BLOG: string = 'blog'
export const TERMS: string = 'terms'
export const POLICY: string = 'policy'
export const PROFILE: string = 'profile'
export const LOGIN: string = 'login'
export const RECOVERY: string = 'recovery'
export const PARTNERS: string = 'partners'
export const REFERRALS: string = 'referrals'
export const ONBOARDING: string = 'onboarding'
export const CABINET: string = 'cabinet'
export const REVIEWS: string = 'reviews'
export const CONTACT: string = 'contact'
export const CABINET__BOOKINGS: string = 'cabinet__bookings'
export const CABINET__REPORTS: string = 'cabinet__reports'
export const CABINET__LOCATIONS: string = 'cabinet__locations'
export const CABINET__METRICS: string = 'cabinet__metrics'
export const CABINET__PAYOUT_SETTINGS: string = 'cabinet__payout-settings'
export const CABINET__INSTRUCTION: string = 'cabinet__instruction'
export const REFERRAL_CABINET: string = 'referral-cabinet'
export const COMPANIES_MAP: string = 'companies-map'
export const COMPANIES_MAP__OPEN: string = 'companies-map__open'
export const COMPANIES_MAP__OPEN__BOOK: string = 'companies-map__open__book'
export const COMPANIES_MAP__OPEN__BOOK__PAY: string = 'companies-map__open__book__pay'
export const MY_ORDERS: string = 'my-orders'
export const MY_ORDERS__OPEN: string = 'my-orders__open'
export const MY_ORDERS__OPEN__INSTRUCTION: string = 'my-orders__open__instruction'
export const MY_ORDERS__OPEN__EDIT: string = 'my-orders__open__edit'
export const PAYMENT__SUCCESS: string = 'payment__success'
export const PERKS: string = 'perks'
export const ERROR_404: string = 'error404'
