
import { computed, defineComponent } from 'vue'
import CityAndAttractionTemplate from 'components/template/city-and-attraction-template.vue'
import cityPrefetch from 'src/compositions/prefetch/cityPrefetch'
import { CITY_ALIAS } from 'pages/names'
import { useRouter } from 'vue-router'
import { pathToKey } from 'src/functions/utils'
import { ServiceTypeAlias } from 'src/model/common.model'

const name = CITY_ALIAS

export default defineComponent({
  name,
  components: {
    CityTemplate: CityAndAttractionTemplate
  },
  async preFetch(options) {
    await cityPrefetch(options)
  },
  setup() {
    const { currentRoute } = useRouter()

    const path = computed(() => currentRoute.value.params.serviceTypeAlias as string)
    const name = computed(() => `city-${ path.value }`)
    const alias = computed(() => pathToKey(path.value) as ServiceTypeAlias)

    return {
      name,
      alias,
      path
    }
  }
})
