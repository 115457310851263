import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, renderSlot as _renderSlot, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "row items-center justify-center responsive-preview-template" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_virtual_scroll = _resolveComponent("q-virtual-scroll")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.$q.screen.gt.xs || !_ctx.mounted)
      ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.items, (item, index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: item.url,
            class: _normalizeClass([_ctx.colClass, "q-px-md"])
          }, [
            _renderSlot(_ctx.$slots, "default", {
              index: index,
              item: item
            })
          ], 2))
        }), 128))
      : (_openBlock(), _createBlock(_component_q_virtual_scroll, {
          key: 1,
          ref: "virtualScrollRef",
          items: _ctx.items,
          "virtual-scroll-horizontal": ""
        }, {
          default: _withCtx(({ item, index }) => [
            _createElementVNode("div", {
              class: _normalizeClass(_ctx.xsContainerClass)
            }, [
              _renderSlot(_ctx.$slots, "default", {
                index: index,
                item: item
              })
            ], 2)
          ]),
          _: 3
        }, 8, ["items"]))
  ]))
}