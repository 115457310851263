
import { computed, defineAsyncComponent, defineComponent, ref } from 'vue'
import { useRouter } from 'vue-router'
import PageTemplate from 'components/template/page-template.vue'
import { useI18n$ } from 'boot/i18n'
import useBlogHome from 'stores/blogHome'
import blogPrefetch from 'src/compositions/prefetch/blogPrefetch'
import useRoutes from 'src/compositions/routes'
import useFormat from 'src/compositions/format'
import { QBtn, QPageSticky } from 'quasar'
import { refStore } from 'stores/__common'
import Breadcrumbs from 'components/simple/breadcrumbs.vue'
import ErrorHandleTemplate from 'components/template/error-handle-template.vue'
import usePageExtensions from 'src/compositions/pageExtensions'
import { BLOG } from 'pages/names'
import LuggageButton from 'components/advanced/luggage-button.vue'
import { matArrowBack } from 'src/config/icons'
import useAppData from 'stores/appData'
import useUserPreview from 'stores/userPreview'
import { formatAmount } from 'src/functions/formatting'
import { createAmount } from 'src/functions/utils'
import { DEFAULT_CURRENCY, DEFAULT_SMALL_DAY_PRICE } from 'src/model/constants'
import { ContentBlog } from 'src/model/content.model'

const name = BLOG

const Markdown = defineAsyncComponent(() => import(/* webpackChunkName: "markdown", webpackMode: "lazy" */'components/simple/markdown.vue'))

export default defineComponent({
  name,
  components: {
    QBtn,
    QPageSticky,
    PageTemplate,
    Markdown,
    Breadcrumbs,
    LuggageButton,
    ErrorHandleTemplate,
  },
  async preFetch(options) {
    await blogPrefetch(options)
  },
  setup() {
    const {
      blog: homeBlog,
      loadOne
    } = refStore(useBlogHome())
    const { total: totalReview } = refStore(useAppData())
    const { country, city } = refStore(useUserPreview())
    const {
      tp,
      st,
      t,
      language
    } = useI18n$(name)
    const { fdf } = useFormat()
    const { currentRoute } = useRouter()
    const { toPrevPage } = useRoutes()

    const textBody = ref('')

    const blog = computed(() => homeBlog.value.blog as ContentBlog)
    const body = computed(() => st(homeBlog.value.body))

    const addText = (result: string[], token: any) => {
      if (token.type === 'inline' && token.children?.length) {
        token.children.forEach((c: any) => addText(result, c))
      } else if (token.type === 'text') {
        result.push(token.content)
      }
    }
    const onExtracted = (text: string) => {
      textBody.value = text
    }

    return {
      matArrowBack,
      blog: blog,
      content: homeBlog,
      body,
      tp,
      t,
      st,
      fdf,
      toPrevPage,
      onExtracted,
      loadOne: () => loadOne(currentRoute.value.params.blog as string, language.value),
      ...usePageExtensions({
        page: name,
        titleGetter: () => st(blog.value.title!),
        descriptionGetter: () => st(blog.value.description),
        article: {
          createdDate: homeBlog.value.createdDate,
          modifiedDate: homeBlog.value.modifiedDate,
          body: textBody.value,
          totalReview: totalReview.value,
          dayPrice: formatAmount(createAmount(city.value?.smallItemPrices?.dayPrice ?? country.value?.smallItemPrices?.dayPrice ?? DEFAULT_SMALL_DAY_PRICE, country.value?.currency ?? DEFAULT_CURRENCY)),
        },
        breadcrumbs: (title) => ([{
          path: 'blog',
          label: t('global.pages.blog')
        }, {
          path: homeBlog.value.path,
          name: title,
          label: title
        }]),
        image: blog.value.image,
        excludeAlternate: true
      })
    }
  }
})
