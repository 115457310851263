import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "row items-center justify-center q-px-sm" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_object_card = _resolveComponent("object-card")!
  const _component_heading_section_template = _resolveComponent("heading-section-template")!
  const _component_home_content_page_template = _resolveComponent("home-content-page-template")!

  return (_openBlock(), _createBlock(_component_home_content_page_template, {
    "fallback-src": _ctx.attraction ? _ctx.ATTRACTION_FALLBACK_IMG : _ctx.CITY_FALLBACK_IMG,
    title: _ctx.tp('title', _ctx.params),
    description: _ctx.tp('description', _ctx.params),
    "companies-title": _ctx.tp('companiesTitle', _ctx.params),
    "content-title": _ctx.tp('contentTitle', _ctx.params),
    params: _ctx.params,
    "faq-entries": _ctx.faqEntries,
    breadcrumbs: _ctx.breadcrumbEntries,
    companies: _ctx.companies,
    content: _ctx.content,
    src: _ctx.src,
    "url-query": _ctx.urlQuery,
    type: _ctx.type
  }, {
    ending: _withCtx(() => [
      (_ctx.filteredAttractions.length)
        ? (_openBlock(), _createBlock(_component_heading_section_template, {
            key: 0,
            title: _ctx.tp('attractions', _ctx.params),
            "margin-bottom": "",
            "no-side-padding": ""
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredAttractions, (a) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: a.key,
                    class: "col-6 col-sm-4 col-md-3 col-lg-2 q-pa-sm"
                  }, [
                    _createVNode(_component_object_card, {
                      src: a.photoURL,
                      title: _ctx.st(a.name),
                      to: _ctx.toAttraction(a),
                      "fallback-src": "/images/attraction-fallback.jpg"
                    }, null, 8, ["src", "title", "to"])
                  ]))
                }), 128))
              ])
            ]),
            _: 1
          }, 8, ["title"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["fallback-src", "title", "description", "companies-title", "content-title", "params", "faq-entries", "breadcrumbs", "companies", "content", "src", "url-query", "type"]))
}