import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createSlots as _createSlots } from "vue"

const _hoisted_1 = { class: "row items-center justify-center home-content-page-template__image" }
const _hoisted_2 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_breadcrumbs = _resolveComponent("breadcrumbs")!
  const _component_q_skeleton = _resolveComponent("q-skeleton")!
  const _component_q_img = _resolveComponent("q-img")!
  const _component_content_template = _resolveComponent("content-template")!
  const _component_heading_section_template = _resolveComponent("heading-section-template")!
  const _component_markdown = _resolveComponent("markdown")!
  const _component_home_page_template = _resolveComponent("home-page-template")!

  return (_openBlock(), _createBlock(_component_home_page_template, {
    companies: _ctx.companies,
    "companies-title": _ctx.companiesTitle,
    description: _ctx.description,
    "faq-entries": _ctx.faqEntries,
    insurance: _ctx.params.insurance,
    title: _ctx.title,
    "url-query": _ctx.urlQuery,
    type: _ctx.type || 'ALL',
    params: _ctx.params,
    class: "home-content-page-template",
    onLeaveLuggage: _ctx.leaveLuggage
  }, _createSlots({
    "after-preview": _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_q_img, {
          alt: _ctx.title,
          ratio: _ctx.$q.screen.xs ? 1.75 : (_ctx.$q.screen.gt.xs ? 4 : 3.5),
          src: _ctx.loadingError ? _ctx.fallbackSrc : _ctx.src,
          basic: "",
          loading: "eager",
          onError: _ctx.onLoadError,
          onLoad: _ctx.onLoadFinished
        }, {
          loading: _withCtx(() => [
            _createVNode(_component_q_skeleton, { class: "fit bg-grey-4" })
          ]),
          _: 1
        }, 8, ["alt", "ratio", "src", "onError", "onLoad"])
      ])
    ]),
    "pre-faq": _withCtx(() => [
      (_ctx.articles.length)
        ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.articles, (c, i) => {
            return (_openBlock(), _createBlock(_component_heading_section_template, {
              key: i,
              title: c.title,
              "margin-bottom": ""
            }, {
              default: _withCtx(() => [
                _createVNode(_component_content_template, null, {
                  default: _withCtx(() => [
                    _createElementVNode("div", {
                      innerHTML: c.content
                    }, null, 8, _hoisted_2)
                  ]),
                  _: 2
                }, 1024)
              ]),
              _: 2
            }, 1032, ["title"]))
          }), 128))
        : _createCommentVNode("", true),
      (_ctx.contentText)
        ? (_openBlock(), _createBlock(_component_heading_section_template, {
            key: 1,
            title: _ctx.contentTitle,
            "margin-bottom": "",
            "margin-top": ""
          }, {
            default: _withCtx(() => [
              _createVNode(_component_markdown, { text: _ctx.contentText }, null, 8, ["text"])
            ]),
            _: 1
          }, 8, ["title"]))
        : _createCommentVNode("", true)
    ]),
    "post-faq": _withCtx(() => [
      _renderSlot(_ctx.$slots, "ending")
    ]),
    _: 2
  }, [
    (_ctx.breadcrumbs.length)
      ? {
          name: "breadcrumbs",
          fn: _withCtx(() => [
            _createVNode(_component_breadcrumbs, { values: _ctx.breadcrumbs }, null, 8, ["values"])
          ]),
          key: "0"
        }
      : undefined
  ]), 1032, ["companies", "companies-title", "description", "faq-entries", "insurance", "title", "url-query", "type", "params", "onLeaveLuggage"]))
}