import { PreFetchOptions } from '@quasar/app-webpack'
import { ErrorNotFound } from 'src/model/common.model'
import { ERROR_404 } from 'pages/names'

export const handleNotFound = (options: PreFetchOptions<any>) => {
  if (options.ssrContext) {
    throw new ErrorNotFound(404)
  } else {
    return options.redirect({ name: ERROR_404 }, 404)
  }
}
