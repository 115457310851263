import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "column full-height text-black" }
const _hoisted_2 = { class: "col full-width" }
const _hoisted_3 = { class: "col-auto full-width" }
const _hoisted_4 = { class: "row fit items-center q-pa-md" }
const _hoisted_5 = { class: "col-12 q-pb-sm" }
const _hoisted_6 = { class: "col-auto" }
const _hoisted_7 = { class: "col text-right" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_image = _resolveComponent("icon-image")!
  const _component_q_item = _resolveComponent("q-item")!
  const _component_q_separator = _resolveComponent("q-separator")!
  const _component_service_selector = _resolveComponent("service-selector")!
  const _component_admin_btn = _resolveComponent("admin-btn")!
  const _component_q_no_ssr = _resolveComponent("q-no-ssr")!
  const _component_page_links = _resolveComponent("page-links")!
  const _component_q_item_label = _resolveComponent("q-item-label")!
  const _component_q_item_section = _resolveComponent("q-item-section")!
  const _component_location_select = _resolveComponent("location-select")!
  const _component_language_selector = _resolveComponent("language-selector")!
  const _component_authorization_button = _resolveComponent("authorization-button")!
  const _component_q_drawer = _resolveComponent("q-drawer")!

  return (_openBlock(), _createBlock(_component_q_drawer, {
    breakpoint: 800,
    "model-value": _ctx.opened,
    width: _ctx.$q.screen.xs ? 300 : 400,
    bordered: "",
    class: "page-drawer",
    overlay: "",
    "onUpdate:modelValue": _ctx.toggle
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_q_item, { class: "q-pb-xs" }, {
            default: _withCtx(() => [
              _createVNode(_component_icon_image, {
                to: _ctx.toRoot(),
                alt: "Qeepl logo",
                height: "32",
                name: "logo",
                width: "64",
                onClick: _ctx.toggle
              }, null, 8, ["to", "onClick"])
            ]),
            _: 1
          }),
          _createVNode(_component_q_separator, { class: "q-mx-md q-mb-sm" }),
          _createVNode(_component_q_item, null, {
            default: _withCtx(() => [
              _createVNode(_component_service_selector)
            ]),
            _: 1
          }),
          _createVNode(_component_q_no_ssr, null, {
            default: _withCtx(() => [
              (_ctx.isAdmin || _ctx.isSecondaryAdmin)
                ? (_openBlock(), _createBlock(_component_admin_btn, {
                    key: 0,
                    onClick: _ctx.toggle
                  }, null, 8, ["onClick"]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }),
          _createVNode(_component_page_links, {
            mode: "item",
            onClick: _ctx.toggle
          }, null, 8, ["onClick"]),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.pages, (page) => {
            return (_openBlock(), _createBlock(_component_q_item, {
              key: page,
              to: _ctx.toPage(page),
              onClick: _ctx.toggle
            }, {
              default: _withCtx(() => [
                _createVNode(_component_q_item_section, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_q_item_label, null, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.tp(page)), 1)
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 2
                }, 1024)
              ]),
              _: 2
            }, 1032, ["to", "onClick"]))
          }), 128))
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_location_select, {
                "no-dates-chooser": "",
                "no-bags-chooser": "",
                "on-click": _ctx.toggle
              }, null, 8, ["on-click"])
            ]),
            _createElementVNode("div", _hoisted_6, [
              _createVNode(_component_language_selector, { class: "q-pr-sm" })
            ]),
            _createElementVNode("div", _hoisted_7, [
              _createVNode(_component_authorization_button, {
                "no-paddings": "",
                onClick: _ctx.toggle
              }, null, 8, ["onClick"])
            ])
          ])
        ])
      ])
    ]),
    _: 1
  }, 8, ["model-value", "width", "onUpdate:modelValue"]))
}