
import { computed, defineComponent, PropType } from 'vue'
import { Coordinates } from 'src/model/common.model'
import { useI18n$ } from 'boot/i18n'
import { QIcon } from 'quasar'
import { VueClick } from 'components/models'
import { AnonymousAttractionProjection } from 'src/model/attraction.model'
import { computeDirectDestination } from 'src/functions/map'

const name = 'closest-3-places'

const matDirectionsWalk = 'M0 0h24v24H0z@@fill:none;&&M13.5 5.5c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zM9.8 8.9L7 23h2.1l1.8-8 2.1 2v6h2v-7.5l-2.1-2 .6-3C14.8 12 16.8 13 19 13v-2c-1.9 0-3.5-1-4.3-2.4l-1-1.6c-.4-.6-1-1-1.7-1-.3 0-.5.1-.8.1L6 8.3V13h2V9.6l1.8-.7'

export default defineComponent({
  name,
  components: {
    QIcon
  },
  props: {
    coordinates: {
      type: Object as PropType<Coordinates>,
      required: true
    },
    attractions: {
      type: Array as PropType<AnonymousAttractionProjection[]>,
      default: []
    }
  },
  emits: [
    VueClick
  ],
  setup(props) {
    const { t, st } = useI18n$(name)

    const closest3Places = computed(() => {
      return props.attractions
        .map(p => {
          const minutes = Math.floor(computeDirectDestination(props.coordinates, p.center) / 1000 * 15)
          return {
            name: st(p.name),
            away: t('global.away', { minutes })
          }
        })
    })

    return {
      matDirectionsWalk,
      closest3Places
    }
  }
})
