
import { defineComponent } from 'vue'
import { QBtn } from 'quasar'
import IndexTemplate from 'components/template/index-template.vue'
import indexPrefetch from 'src/compositions/prefetch/indexPrefetch'
import { INDEX } from 'pages/names'

const name = INDEX

export default defineComponent({
  name,
  components: {
    QBtn,
    IndexTemplate,
  },
  async preFetch(options) {
    await indexPrefetch(options, 'LUGGAGE')
  },
  setup() {
    return {
      name
    }
  }
})
