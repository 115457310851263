import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_toolbar_title = _resolveComponent("toolbar-title")!
  const _component_admin_btn = _resolveComponent("admin-btn")!
  const _component_q_no_ssr = _resolveComponent("q-no-ssr")!
  const _component_page_links = _resolveComponent("page-links")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_service_selector = _resolveComponent("service-selector")!
  const _component_language_selector = _resolveComponent("language-selector")!
  const _component_authorization_button = _resolveComponent("authorization-button")!
  const _component_q_toolbar = _resolveComponent("q-toolbar")!
  const _component_container_template = _resolveComponent("container-template")!
  const _component_q_header = _resolveComponent("q-header")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_q_header, { class: "bg-white slideInDown animated page-header gt-sm" }, {
      default: _withCtx(() => [
        _createVNode(_component_container_template, null, {
          default: _withCtx(() => [
            _createVNode(_component_q_toolbar, null, {
              default: _withCtx(() => [
                _createVNode(_component_toolbar_title),
                _createVNode(_component_q_no_ssr, null, {
                  default: _withCtx(() => [
                    (_ctx.isAdmin || _ctx.isSecondaryAdmin)
                      ? (_openBlock(), _createBlock(_component_admin_btn, { key: 0 }))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }),
                _createVNode(_component_page_links),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.anonymousPages, (page, i) => {
                  return (_openBlock(), _createBlock(_component_q_btn, {
                    key: page,
                    label: _ctx.tp(page),
                    to: _ctx.toPage(page),
                    class: "text-transform-none",
                    color: "black",
                    flat: ""
                  }, null, 8, ["label", "to"]))
                }), 128)),
                _createVNode(_component_service_selector, { class: "q-pl-md q-pr-sm" }),
                _createVNode(_component_language_selector, { class: "q-pl-md q-pr-sm" }),
                _createVNode(_component_authorization_button)
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_q_btn, {
      icon: _ctx.matMenu,
      class: "lt-md fixed-top-right shadow-10 text-grey-7 z-fab q-mr-sm q-mt-xs",
      color: "white",
      padding: "sm",
      rounded: "",
      size: "md",
      onClick: _ctx.toggle
    }, null, 8, ["icon", "onClick"])
  ], 64))
}