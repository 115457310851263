import { LanguageContainer } from 'src/model/language.model'
import { BaseUserProjection } from 'src/model/user.model'
import { OrderItemType } from 'src/model/order.model'

export interface Domain {
  id?: string
}

export type Currency = 'USD' | 'RUB' | 'GBP' | 'EUR' | 'MXN' | 'TRY' | 'JPY' | 'CNY' | 'THB'
  | 'KRW' | 'VND' | 'INR' | 'ILS' | 'EGP' | 'AED' | 'ARS' | 'BRL' | 'CAD' | 'CLP'
  | 'COP' | 'CUP' | 'ECS' | 'IDR' | 'MYR' | 'PEN' | 'SAR' | 'SGD' | 'VES' | 'ZAR'
  | 'AUD' | 'HKD' | 'PLN' | 'HUF' | 'AMD' | 'UZS'

export const Currencies: Currency[] = ['USD', 'RUB', 'GBP', 'EUR', 'MXN', 'TRY', 'JPY', 'CNY', 'THB',
  'KRW', 'VND', 'INR', 'ILS', 'EGP', 'AED', 'ARS', 'BRL', 'CAD', 'CLP',
  'COP', 'CUP', 'ECS', 'IDR', 'MYR', 'PEN', 'SAR', 'SGD', 'VES', 'ZAR',
  'AUD', 'HKD', 'PLN', 'HUF', 'AMD', 'UZS']

export type OtpMethod = 'SMS' | 'WHATSAPP' | 'VOICE' | 'EMAIL'

export type ServiceType = 'DEFAULT' | 'SELF'

export const ServiceTypes: ServiceType[] = ['DEFAULT', 'SELF']

export interface ServiceTypeAware<DEFAULT, SELF> {
  type: ServiceType
  default?: DEFAULT
  self?: SELF
}

export interface Range<T> {
  from: T
  to: T
}

export interface DateRange extends Range<string> {
}

export interface DateTimeRange extends Range<string> {
}

export interface TimeRange extends Range<string> {
}

export interface DateTime {
  date: string
  time: string
}

export interface DistanceTo {
  name: string
  value: number
}

export interface Path {
  from: Coordinates
  to: Coordinates
  distance: number
  points: Coordinates[]
}

export interface Location {
  point: Coordinates
  description?: string
  accuracyInMeters?: number
}

export interface Coordinates {
  latitude: number
  longitude: number
}

export interface GeoPoint {
  name: string
  center?: Coordinates
  pointId?: string
}

export interface AutocompletePoint {
  pointId?: string
  description: string
  caption?: string
  coordinates?: Coordinates
}

export interface CoordinatesInfo {
  address: string
  countryKey: string
  city: string
}

export interface LookupResult extends CoordinatesInfo {
  coordinates: Coordinates
}

export interface Boundaries {
  leftLower: Coordinates
  rightUpper: Coordinates
}

export interface Named {
  name: LanguageContainer<string>
}

export interface Bordered {
  boundaries: Boundaries
}

export interface Keyed<T> {
  key: T
}

export interface HasPhotoURL {
  photoURL: string
}

export interface FAQEntry {
  question: string
  answer: string
}

export interface Amount {
  value: number
  currency: Currency
}

export interface Pageable {
  page?: number
  size?: number
  sortBy?: string
  descending?: boolean
}

export interface Page<T> {
  page?: number
  totalCount: number
  data: T[]
}

export namespace Pageable {
  export function map(pageable?: Pageable): string {
    if (pageable && pageable.page !== undefined && pageable.size !== undefined) {
      return `?page=${ pageable.page }&size=${ pageable.size }${ pageable.sortBy ? `&sort=${ pageable.sortBy }${ pageable.descending ? `,DESC` : ',ASC' }` : '' }`
    }
    return ''
  }
}

export namespace Page {
  export function of<T>(page: Page<T>, pageable?: Pageable): Page<T> {
    return {
      ...page,
      page: pageable?.page
    }
  }

  export function empty<T>(): Page<T> {
    return {
      data: [],
      totalCount: 0,
      page: 0
    }
  }
}

export namespace QueryParams {
  export function of(object?: { [key: string]: string | undefined }): string {
    if (object) {
      const keys = Object.keys(object)
      if (keys.length > 0) {
        return `?${ keys.map(key => object[key] ? `${ key }=${ object[key] }` : '').filter(v => !!v).join('&') }`
      }
    }
    return ''
  }
}

export interface BaseCompany {
  id: any
  publicName: LanguageContainer<string>
}

export interface BaseUser extends BaseUserProjection {
}

export interface BasePartner {
  id: any
  legalName: string
}

export interface BasePartnerProjection extends BasePartner {
  country: string
}

export type TypedContainer<K extends string, T> = Partial<Record<K, T>>

export interface Container<T> {
  [key: string]: T
}

export interface NumberContainer extends Container<number> {
}

export interface Phone {
  code: string
  number: string
}

export interface UploadResponse {
  name: string
  url?: string
  error?: string
}

export interface TotalReview {
  count: number
  value: number
}

export type CountryToAmountDictionary = {
  [index in string]: Amount
}

export type PaymentSystem = 'GOOGLE_PAY' | 'APPLE_PAY'

export type CountryChoice = 'RU' | 'default'

export interface IPLocation extends Location {
  accuracyInMeters: number
  country: string
}

export interface PhoneOption {
  iso: string
  format: string
  code: string
  img: string
  label: string
  priority: number
  numbersCount: number
}

export interface SelectorOption {
  value: string
  label: string
}

export interface PayoutSettingsPaypal {
  email: string
}

export interface PayoutSettingsStripe {
  accountId: string
  onboardingFinished: boolean
}

export interface PayoutSettingsAccount {
  accountNumber?: string
  bankBic?: string
  inn?: string
  kpp?: string
}

export interface PayoutSettingsCard {
  cardNumber?: string
}

export interface PayoutSettings {
  legalName?: string
  paypal?: PayoutSettingsPaypal
  stripe?: PayoutSettingsStripe
  account?: PayoutSettingsAccount
  card?: PayoutSettingsCard
}

export interface BreadcrumbEntry {
  to: string
  label: string
}

export type Measure = 'FT' | 'M'

export const Measures = ['FT', 'M']

export type LargeMeasure = 'MI' | 'KM'

export const LargeMeasures = ['MI', 'KM']

export type ServiceTypeAlias =
  'LUGGAGE'
  | 'SELF'

export const ServiceTypeAliases: ServiceTypeAlias[] = [
  'LUGGAGE',
  'SELF'
]

export type ChargeType =
  'HOURLY'
  | 'DAILY'
  | 'MONTHLY'

export const ChargeTypes: ChargeType[] = ['HOURLY', 'DAILY', 'MONTHLY']

export type PaymentProvider = 'STRIPE' | 'YOOKASSA'

export class ErrorNotFound extends Error {
  code: number

  constructor(code: number = 404) {
    super('Not Found')
    this.code = code
  }
}

export type ItemsMetadata = TypedContainer<OrderItemType, number>

export interface ItemSizeContainer<T> {
  'S': T
  'M': T
  'L': T
}

export interface MayHavePrice {
  hourPrice?: number
  dayPrice?: number
  monthPrice?: number
}

export interface HasPrice {
  hourPrice: number
  dayPrice: number
  monthPrice: number
}

export interface MayHavePriceContainer extends MayHavePrice {
  smallItemPrices?: MayHavePrice
  largeItemPrices?: MayHavePrice
}

export interface HasPriceContainer extends HasPrice {
  smallItemPrices: HasPrice
  largeItemPrices: HasPrice
}
