import { ref } from 'vue'
import { defineStore, } from 'pinia'

const useServerRender = defineStore('serverRender', () => {
  const code = ref<404 | 410 | 500 | 200>(200)
  const sourcePage = ref<string | null>(null)
  const internalPage = ref<string | null>(null)

  return {
    code,
    sourcePage,
    internalPage
  }
})

export default useServerRender
