import { computed, Ref, watch } from 'vue'
import { useI18n$ } from 'boot/i18n'
import useUserPreview from 'stores/userPreview'
import useUserLocation from 'stores/userLocation'
import { refStore } from 'stores/__common'
import { formatAmount } from 'src/functions/formatting'
import { formatDate, YEAR_PATTERN } from 'src/functions/date'
import { City } from 'src/model/city.model'
import { Attraction } from 'src/model/attraction.model'
import { Country } from 'src/model/country.model'
import { createAmount, keyToPath } from 'src/functions/utils'
import { sortByDirectClosest } from 'src/functions/map'

export default function useHome(page: string) {
  const { country, city, attraction, companies } = refStore(useUserPreview())
  const { setLocationText } = useUserLocation()
  const { st, tp, t } = useI18n$(page)

  const params = computed(() => {
    const attractionValue = attraction.value as Attraction | null
    const cityValue = city.value as City
    const countryValue = country.value as Country
    return attractionValue ? createAttractionParams(attractionValue, cityValue, countryValue) :
      createCityParams(cityValue, countryValue)
  })
  const filteredCompanies = computed(() => {
    const center = attraction.value?.center || city.value?.center || null
    const companiesValue = sortByDirectClosest(companies.value, center)
    return companiesValue.length >= 6 ? companiesValue.slice(0, 6) : companiesValue
  })

  const createCityParams = (city: City, country: Country) => {
    const countryName = st(country.name)
    const cityName = st(city.name)
    const hourPriceValue = createAmount(city.smallItemPrices?.hourPrice ?? country.smallItemPrices?.hourPrice, country.currency)
    const dayPriceValue = createAmount(city.smallItemPrices?.dayPrice ?? country.smallItemPrices?.dayPrice, country.currency)
    const monthPriceValue = createAmount(city.smallItemPrices?.monthPrice ?? country.smallItemPrices?.monthPrice, country.currency)
    const insurance = createAmount(country.insurance, country.currency)
    const dayPrice = formatAmount(dayPriceValue)
    const params = {
      country: countryName,
      countryKey: country.key,
      city: cityName,
      cityKey: city.key,
      cityAlternateName: st(city.alternateName) || cityName,
      dayPrice,
      dayPriceText: t('global.dayPrice.small', { price: dayPrice }),
      hourPrice: formatAmount(hourPriceValue),
      amount: dayPrice,
      hourPriceValue,
      dayPriceValue,
      monthPriceValue,
      monthPrice: formatAmount(monthPriceValue),
      insurance,
      insuranceText: formatAmount(insurance, 0),
      cityPath: keyToPath(city.key),
      year: formatDate(new Date(), YEAR_PATTERN),
      locationText: `${ countryName }, ${ cityName }`
    }
    return {
      ...params,
      phrase1: tp('phrase1', params),
      phrase2: tp('phrase2', params),
    }
  }

  const createAttractionParams = (attraction: Attraction, city: City, country: Country) => {
    const cityParams = createCityParams(city, country)
    const attractionName = st(attraction.name)
    const params = {
      ...cityParams,
      attraction: attractionName,
      attractionKey: attraction.key,
      attractionPath: keyToPath(attraction.key),
      attractionAlternateName: st(attraction.alternateName) || attractionName,
      locationText: `${ cityParams.locationText }, ${ attractionName }`
    }
    return {
      ...params,
      phrase1: tp('phrase1', params),
      phrase2: tp('phrase2', params),
    }
  }

  watch(() => params.value.locationText, newLocationText => {
    setLocationText(newLocationText)
  })

  setLocationText(params.value.locationText)

  return {
    allCompanies: companies,
    companies: filteredCompanies,
    cityParams: params as unknown as Ref<ReturnType<typeof createCityParams>>,
    attractionParams: params as unknown as Ref<ReturnType<typeof createCityParams> & ReturnType<typeof createAttractionParams>>,
    country,
    city,
    attraction,
  }
}
