import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, withCtx as _withCtx, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createSlots as _createSlots } from "vue"

const _hoisted_1 = { class: "row q-px-md q-pt-sm q-mb-md q-pb-sm justify-center" }
const _hoisted_2 = { class: "col-12 col-md-11 col-lg-10 col-xl-9" }
const _hoisted_3 = { class: "row items-stretch justify-around" }
const _hoisted_4 = { class: "col-12 col-sm-4" }
const _hoisted_5 = {
  width: "111",
  height: "111",
  viewBox: "0 0 111 111",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  "svg-inline": '',
  role: 'presentation',
  focusable: 'false',
  tabindex: '-1'
}
const _hoisted_6 = { class: "col-12 col-sm-4" }
const _hoisted_7 = {
  width: "111",
  height: "111",
  viewBox: "0 0 111 111",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  "svg-inline": '',
  role: 'presentation',
  focusable: 'false',
  tabindex: '-1'
}
const _hoisted_8 = { class: "col-12 col-sm-4" }
const _hoisted_9 = {
  width: "111",
  height: "111",
  viewBox: "0 0 111 111",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  "svg-inline": '',
  role: 'presentation',
  focusable: 'false',
  tabindex: '-1'
}
const _hoisted_10 = { class: "row items-stretch justify-around" }
const _hoisted_11 = { class: "col-12 q-pt-md" }
const _hoisted_12 = { class: "row items-center justify-center" }
const _hoisted_13 = { class: "col-12 col-md-6" }
const _hoisted_14 = { class: "row items-center justify-center" }
const _hoisted_15 = { class: "row items-center justify-center q-pt-md" }
const _hoisted_16 = { class: "col-auto" }
const _hoisted_17 = { class: "row items-center justify-center q-pt-sm" }
const _hoisted_18 = { class: "col-auto" }
const _hoisted_19 = { class: "row items-stretch justify-around" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_qeepl_rating = _resolveComponent("qeepl-rating")!
  const _component_location_select = _resolveComponent("location-select")!
  const _component_advantage_icons = _resolveComponent("advantage-icons")!
  const _component_how_to_item = _resolveComponent("how-to-item")!
  const _component_heading_section_template = _resolveComponent("heading-section-template")!
  const _component_storing_card = _resolveComponent("storing-card")!
  const _component_closest3_places = _resolveComponent("closest3-places")!
  const _component_company_card = _resolveComponent("company-card")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_review_card = _resolveComponent("review-card")!
  const _component_responsive_preview_template = _resolveComponent("responsive-preview-template")!
  const _component_price_banner = _resolveComponent("price-banner")!
  const _component_home_values_card = _resolveComponent("home-values-card")!
  const _component_faq = _resolveComponent("faq")!
  const _component_articles = _resolveComponent("articles")!
  const _component_q_no_ssr = _resolveComponent("q-no-ssr")!
  const _component_app_links_banner = _resolveComponent("app-links-banner")!
  const _component_app_top_banner = _resolveComponent("app-top-banner")!
  const _component_page_template = _resolveComponent("page-template")!

  return (_openBlock(), _createBlock(_component_page_template, {
    class: "home-page-template",
    "overflow-up": "",
    position: "center",
    description: _ctx.description,
    i18n: _ctx.$options.name,
    title: _ctx.title
  }, _createSlots({
    "after-preview": _withCtx(() => [
      _createVNode(_component_qeepl_rating, {
        dense: _ctx.$q.screen.xs,
        class: "q-my-lg",
        fill: ""
      }, null, 8, ["dense"]),
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_location_select, {
            "on-click": _ctx.leaveLuggage,
            placeholder: _ctx.searchPlaceholder,
            query: _ctx.urlQuery,
            "no-bags-chooser": _ctx.noLocationSelectOptions,
            "no-dates-chooser": _ctx.noLocationSelectOptions
          }, null, 8, ["on-click", "placeholder", "query", "no-bags-chooser", "no-dates-chooser"])
        ])
      ]),
      _createVNode(_component_advantage_icons, {
        insurance: _ctx.insurance,
        class: "q-px-md"
      }, null, 8, ["insurance"]),
      _renderSlot(_ctx.$slots, "after-preview")
    ]),
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "before-how-to"),
      _createVNode(_component_heading_section_template, {
        title: _ctx.tp(`howTo.${ _ctx.serviceType }.title`, _ctx.params),
        "margin-bottom": "",
        "margin-top": ""
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_how_to_item, {
                title: _ctx.tp(`howTo.${ _ctx.serviceType }.book.title`, _ctx.params),
                description: _ctx.tp(`howTo.${ _ctx.serviceType }.book.description`)
              }, {
                default: _withCtx(() => [
                  (_openBlock(), _createElementBlock("svg", _hoisted_5, _cache[0] || (_cache[0] = [
                    _createElementVNode("g", { "clip-path": "url(#clip0_206_78)" }, [
                      _createElementVNode("path", {
                        d: "M82 0H29C12.984 0 0 12.984 0 29v53c0 16.016 12.984 29 29 29h53c16.016 0 29-12.984 29-29V29c0-16.016-12.984-29-29-29z",
                        fill: "#F8FAFF"
                      }),
                      _createElementVNode("g", { "clip-path": "url(#clip1_206_78)" }, [
                        _createElementVNode("g", { filter: "url(#filter0_f_206_78)" }, [
                          _createElementVNode("path", {
                            d: "M45 59.066C45 54.057 49.923 50 56 50s11 4.057 11 9.066C67 64.074 56 76 56 76S45 64.074 45 59.066z",
                            fill: "url(#paint0_linear_206_78)"
                          })
                        ]),
                        _createElementVNode("path", {
                          d: "M41 47.342C41 38.866 47.713 32 56 32c8.287 0 15 6.866 15 15.342 0 7.302-11.133 23.292-14.216 27.581a.956.956 0 01-1.568 0C52.132 70.633 41 54.643 41 47.343z",
                          fill: "url(#paint1_linear_206_78)"
                        }),
                        _createElementVNode("path", {
                          d: "M56 41c-4.418 0-8 3.358-8 7.5 0 4.142 3.582 7.5 8 7.5s8-3.358 8-7.5c0-4.142-3.582-7.5-8-7.5z",
                          fill: "url(#paint2_linear_206_78)"
                        })
                      ])
                    ], -1),
                    _createElementVNode("defs", null, [
                      _createElementVNode("linearGradient", {
                        id: "paint0_linear_206_78",
                        x1: "56",
                        y1: "50",
                        x2: "56",
                        y2: "76",
                        gradientUnits: "userSpaceOnUse"
                      }, [
                        _createElementVNode("stop", { "stop-color": "#6B36E6" }),
                        _createElementVNode("stop", {
                          offset: "1",
                          "stop-color": "#70AEFF"
                        })
                      ]),
                      _createElementVNode("linearGradient", {
                        id: "paint1_linear_206_78",
                        x1: "56",
                        y1: "32",
                        x2: "56",
                        y2: "76",
                        gradientUnits: "userSpaceOnUse"
                      }, [
                        _createElementVNode("stop", { "stop-color": "#6B36E6" }),
                        _createElementVNode("stop", {
                          offset: "1",
                          "stop-color": "#70AEFF"
                        })
                      ]),
                      _createElementVNode("linearGradient", {
                        id: "paint2_linear_206_78",
                        x1: "56.001",
                        y1: "41",
                        x2: "56.001",
                        y2: "56",
                        gradientUnits: "userSpaceOnUse"
                      }, [
                        _createElementVNode("stop", { "stop-color": "#C2D1FF" }),
                        _createElementVNode("stop", {
                          offset: "1",
                          "stop-color": "#EDE9FF"
                        })
                      ]),
                      _createElementVNode("clipPath", { id: "clip0_206_78" }, [
                        _createElementVNode("path", {
                          fill: "#fff",
                          d: "M0 0h111v111H0z"
                        })
                      ]),
                      _createElementVNode("clipPath", { id: "clip1_206_78" }, [
                        _createElementVNode("path", {
                          fill: "#fff",
                          transform: "translate(35 32)",
                          d: "M0 0h42v54H0z"
                        })
                      ]),
                      _createElementVNode("filter", {
                        id: "filter0_f_206_78",
                        x: "35",
                        y: "40",
                        width: "42",
                        height: "46",
                        filterUnits: "userSpaceOnUse",
                        "color-interpolation-filters": "sRGB"
                      }, [
                        _createElementVNode("feFlood", {
                          "flood-opacity": "0",
                          result: "BackgroundImageFix"
                        }),
                        _createElementVNode("feBlend", {
                          in: "SourceGraphic",
                          in2: "BackgroundImageFix",
                          result: "shape"
                        }),
                        _createElementVNode("feGaussianBlur", {
                          stdDeviation: "5",
                          result: "effect1_foregroundBlur_206_78"
                        })
                      ])
                    ], -1)
                  ])))
                ]),
                _: 1
              }, 8, ["title", "description"])
            ]),
            _createElementVNode("div", _hoisted_6, [
              _createVNode(_component_how_to_item, {
                title: _ctx.tp(`howTo.${ _ctx.serviceType }.leave.title`, _ctx.params),
                description: _ctx.tp(`howTo.${ _ctx.serviceType }.leave.description`)
              }, {
                default: _withCtx(() => [
                  (_openBlock(), _createElementBlock("svg", _hoisted_7, _cache[1] || (_cache[1] = [
                    _createElementVNode("path", {
                      d: "M82 0H29C12.984 0 0 12.984 0 29v53c0 16.016 12.984 29 29 29h53c16.016 0 29-12.984 29-29V29c0-16.016-12.984-29-29-29z",
                      fill: "#F8FAFF"
                    }, null, -1),
                    _createElementVNode("mask", {
                      id: "a",
                      style: {"mask-type":"luminance"},
                      maskUnits: "userSpaceOnUse",
                      x: "40",
                      y: "44",
                      width: "33",
                      height: "33"
                    }, [
                      _createElementVNode("path", {
                        d: "M73 44H40v33h33V44z",
                        fill: "#fff"
                      })
                    ], -1),
                    _createElementVNode("g", {
                      opacity: ".4",
                      filter: "url(#filter0_f_0_1)",
                      mask: "url(#a)"
                    }, [
                      _createElementVNode("path", {
                        d: "M55.21 51.09a.967.967 0 01-.966-.967v-5.156a.967.967 0 011.934 0v5.156a.967.967 0 01-.967.967zm8.702-.967v-5.156a.967.967 0 00-1.934 0v5.156a.967.967 0 101.934 0zm-15.469 0v-5.156a.967.967 0 00-1.933 0v5.156a.967.967 0 101.933 0zm21.27 14.32V53.99H40.709v17.403c0 1.599 1.301 2.9 2.9 2.9h15.973a7.09 7.09 0 0010.131-9.85z",
                        fill: "url(#paint0_linear_0_1)"
                      }),
                      _createElementVNode("path", {
                        d: "M49.41 62.691h-3.867a.967.967 0 01-.967-.966v-3.868c0-.533.433-.966.967-.966h3.867c.534 0 .967.433.967.966v3.868a.967.967 0 01-.967.966z",
                        fill: "#FFB3B3"
                      }),
                      _createElementVNode("path", {
                        d: "M50.377 65.592v3.867a.967.967 0 01-.967.967h-3.867a.967.967 0 01-.967-.967v-3.867c0-.534.433-.967.967-.967h3.867c.534 0 .967.433.967.967zm2.9-8.701a.967.967 0 00-.967.966v3.868c0 .533.433.966.967.966h3.867a.967.967 0 00.967-.966v-3.868a.967.967 0 00-.966-.966h-3.868zm16.436-7.413c0-1.599-1.301-2.9-2.9-2.9h-2.9v3.545a.967.967 0 11-1.934 0v-3.545h-5.801v3.545a.967.967 0 11-1.934 0v-3.545h-5.8v3.545a.967.967 0 11-1.934 0v-3.545h-2.9c-1.6 0-2.901 1.301-2.901 2.9v4.512h29.004v-4.512zm-5.479 15.92a.967.967 0 00-.966.967v1.612H62.3a.967.967 0 100 1.933h1.933a.967.967 0 00.967-.967v-2.578a.967.967 0 00-.967-.967zM49.41 56.891h-3.867a.967.967 0 00-.967.966v3.868c0 .533.433.966.967.966h3.867a.967.967 0 00.967-.966v-3.868a.967.967 0 00-.967-.966zm22.881 12.052c0 4.443-3.614 8.057-8.057 8.057-3.936 0-7.221-2.837-7.919-6.574h-3.038a.967.967 0 01-.967-.967v-3.867c0-.534.433-.967.967-.967h3.867c.093 0 .183.014.268.038a8.12 8.12 0 012.684-2.63.964.964 0 01-.051-.308v-3.868c0-.533.433-.966.967-.966h3.867c.534 0 .967.433.967.966v3.192c3.673.748 6.445 4.004 6.445 7.894zm-1.934 0a6.13 6.13 0 00-6.123-6.123 6.13 6.13 0 00-6.123 6.123 6.13 6.13 0 006.123 6.123 6.13 6.13 0 006.123-6.123z",
                        fill: "url(#paint1_linear_0_1)"
                      })
                    ], -1),
                    _createElementVNode("mask", {
                      id: "b",
                      style: {"mask-type":"luminance"},
                      maskUnits: "userSpaceOnUse",
                      x: "35",
                      y: "31",
                      width: "43",
                      height: "43"
                    }, [
                      _createElementVNode("path", {
                        d: "M78 31H35v43h43V31z",
                        fill: "#fff"
                      })
                    ], -1),
                    _createElementVNode("g", { mask: "url(#b)" }, [
                      _createElementVNode("path", {
                        d: "M54.82 40.238a1.26 1.26 0 01-1.26-1.26V32.26a1.26 1.26 0 012.52 0v6.718a1.26 1.26 0 01-1.26 1.26zm11.338-1.26V32.26a1.26 1.26 0 10-2.52 0v6.718a1.26 1.26 0 002.52 0zm-20.156 0V32.26a1.26 1.26 0 00-2.52 0v6.718a1.26 1.26 0 002.52 0zm27.715 18.66v-13.62H35.924v22.675a3.784 3.784 0 003.78 3.78h20.812a9.238 9.238 0 0013.201-12.835z",
                        fill: "url(#paint2_linear_0_1)"
                      }),
                      _createElementVNode("path", {
                        d: "M47.262 55.355h-5.04a1.26 1.26 0 01-1.26-1.26v-5.038c0-.696.565-1.26 1.26-1.26h5.04c.695 0 1.26.564 1.26 1.26v5.039a1.26 1.26 0 01-1.26 1.26z",
                        fill: "#FFB3B3"
                      }),
                      _createElementVNode("path", {
                        d: "M48.522 59.135v5.039a1.26 1.26 0 01-1.26 1.26h-5.04a1.26 1.26 0 01-1.26-1.26v-5.04c0-.695.565-1.259 1.26-1.259h5.04c.695 0 1.26.564 1.26 1.26zM52.3 47.797a1.26 1.26 0 00-1.26 1.26v5.039c0 .695.564 1.26 1.26 1.26h5.039a1.26 1.26 0 001.26-1.26v-5.04a1.26 1.26 0 00-1.26-1.26H52.3zm21.416-9.658a3.784 3.784 0 00-3.78-3.78h-3.779v4.62a1.26 1.26 0 01-2.52 0v-4.62H56.08v4.62a1.26 1.26 0 01-2.52 0v-4.62h-7.558v4.62a1.26 1.26 0 01-2.52 0v-4.62h-3.779a3.784 3.784 0 00-3.78 3.78v5.879h37.794v-5.88zm-7.139 20.744a1.26 1.26 0 00-1.26 1.26v2.1h-1.26a1.26 1.26 0 000 2.519h2.52a1.26 1.26 0 001.26-1.26v-3.36a1.26 1.26 0 00-1.26-1.26zM47.262 47.797h-5.04a1.26 1.26 0 00-1.26 1.26v5.039c0 .695.565 1.26 1.26 1.26h5.04a1.26 1.26 0 001.26-1.26v-5.04a1.26 1.26 0 00-1.26-1.26zm29.814 15.705c0 5.789-4.71 10.498-10.498 10.498-5.129 0-9.41-3.697-10.32-8.566h-3.957a1.26 1.26 0 01-1.26-1.26v-5.04c0-.695.564-1.259 1.26-1.259h5.039c.12 0 .237.018.348.05a10.58 10.58 0 013.498-3.427 1.258 1.258 0 01-.067-.402v-5.04c0-.695.564-1.26 1.26-1.26h5.039c.696 0 1.26.565 1.26 1.26v4.159c4.786.975 8.398 5.217 8.398 10.287zm-2.52 0c0-4.4-3.578-7.979-7.978-7.979s-7.978 3.58-7.978 7.979c0 4.4 3.579 7.979 7.978 7.979 4.4 0 7.979-3.58 7.979-7.979z",
                        fill: "url(#paint3_linear_0_1)"
                      })
                    ], -1),
                    _createElementVNode("defs", null, [
                      _createElementVNode("linearGradient", {
                        id: "paint0_linear_0_1",
                        x1: "56.017",
                        y1: "76.033",
                        x2: "56.017",
                        y2: "44",
                        gradientUnits: "userSpaceOnUse"
                      }, [
                        _createElementVNode("stop", { "stop-color": "#6B36E6" }),
                        _createElementVNode("stop", {
                          offset: "1",
                          "stop-color": "#70AEFF"
                        })
                      ]),
                      _createElementVNode("linearGradient", {
                        id: "paint1_linear_0_1",
                        x1: "56.5",
                        y1: "77",
                        x2: "56.5",
                        y2: "46.578",
                        gradientUnits: "userSpaceOnUse"
                      }, [
                        _createElementVNode("stop", { "stop-color": "#F0FFF4" }),
                        _createElementVNode("stop", {
                          offset: ".503",
                          "stop-color": "#9CFFAC"
                        }),
                        _createElementVNode("stop", {
                          offset: "1",
                          "stop-color": "#F0FFF4"
                        })
                      ]),
                      _createElementVNode("linearGradient", {
                        id: "paint2_linear_0_1",
                        x1: "55.87",
                        y1: "72.74",
                        x2: "55.87",
                        y2: "31",
                        gradientUnits: "userSpaceOnUse"
                      }, [
                        _createElementVNode("stop", { "stop-color": "#6B36E6" }),
                        _createElementVNode("stop", {
                          offset: "1",
                          "stop-color": "#70AEFF"
                        })
                      ]),
                      _createElementVNode("linearGradient", {
                        id: "paint3_linear_0_1",
                        x1: "56.498",
                        y1: "74",
                        x2: "56.498",
                        y2: "34.359",
                        gradientUnits: "userSpaceOnUse"
                      }, [
                        _createElementVNode("stop", { "stop-color": "#C2D1FF" }),
                        _createElementVNode("stop", {
                          offset: "1",
                          "stop-color": "#EDE9FF"
                        })
                      ]),
                      _createElementVNode("filter", {
                        id: "filter0_f_0_1",
                        x: "26.709",
                        y: "30",
                        width: "59.582",
                        height: "61",
                        filterUnits: "userSpaceOnUse",
                        "color-interpolation-filters": "sRGB"
                      }, [
                        _createElementVNode("feFlood", {
                          "flood-opacity": "0",
                          result: "BackgroundImageFix"
                        }),
                        _createElementVNode("feBlend", {
                          in: "SourceGraphic",
                          in2: "BackgroundImageFix",
                          result: "shape"
                        }),
                        _createElementVNode("feGaussianBlur", {
                          stdDeviation: "7",
                          result: "effect1_foregroundBlur_0_1"
                        })
                      ])
                    ], -1)
                  ])))
                ]),
                _: 1
              }, 8, ["title", "description"])
            ]),
            _createElementVNode("div", _hoisted_8, [
              _createVNode(_component_how_to_item, {
                title: _ctx.tp(`howTo.${ _ctx.serviceType }.enjoy.title`, _ctx.params),
                description: _ctx.tp(`howTo.${ _ctx.serviceType }.enjoy.description`)
              }, {
                default: _withCtx(() => [
                  (_openBlock(), _createElementBlock("svg", _hoisted_9, _cache[2] || (_cache[2] = [
                    _createElementVNode("g", { "clip-path": "url(#clip0_206_58)" }, [
                      _createElementVNode("path", {
                        d: "M82 0H29C12.984 0 0 12.984 0 29v53c0 16.016 12.984 29 29 29h53c16.016 0 29-12.984 29-29V29c0-16.016-12.984-29-29-29z",
                        fill: "#F8FAFF"
                      }),
                      _createElementVNode("g", { filter: "url(#filter0_f_206_58)" }, [
                        _createElementVNode("path", {
                          d: "M54.667 82.5L38 70.833l18.333-14.166 16.667 15L54.667 82.5z",
                          fill: "url(#paint0_linear_206_58)"
                        })
                      ]),
                      _createElementVNode("path", {
                        d: "M43.364 43.037c-3.22.054-6.453.121-9.673.202a4.197 4.197 0 00-.59 2.031 558.038 558.038 0 000 21.189c.026 1.44.845 2.798 2.12 3.551 2.75 1.628 5.5 3.27 8.237 4.91a1052.758 1052.758 0 01-.094-31.883z",
                        fill: "url(#paint1_linear_206_58)"
                      }),
                      _createElementVNode("path", {
                        d: "M41.245 49.828a882.9 882.9 0 00.107 21.498 4.292 4.292 0 002.106 3.593c3.502 2.098 6.977 4.183 10.41 6.228a4.07 4.07 0 001.986.565c4.025-11.34 8.318-22.735 12.343-33.955-8.8-.081-17.588-.081-26.389.013a4.089 4.089 0 00-.563 2.058z",
                        fill: "url(#paint2_linear_206_58)"
                      }),
                      _createElementVNode("path", {
                        d: "M78.07 43.239a815.531 815.531 0 00-22.216-.296v38.785c.698 0 1.409-.188 2.04-.565 6.117-3.646 12.355-7.426 18.647-11.14 1.288-.766 2.093-2.111 2.12-3.551.134-7.063.134-14.126 0-21.188a4.206 4.206 0 00-.59-2.045z",
                        fill: "url(#paint3_linear_206_58)"
                      }),
                      _createElementVNode("path", {
                        d: "M65.11 47.731c-.027-4.305-.067-8.61-.134-12.915-2.375-1.426-4.75-2.852-7.097-4.251a3.989 3.989 0 00-2.04-.565c-.683 0-1.368.188-1.985.565-6.117 3.66-12.356 7.426-18.634 11.153a4.362 4.362 0 00-1.53 1.52c2.697 1.48 5.407 3 8.13 4.534 7.755-.081 15.522-.095 23.29-.04z",
                        fill: "url(#paint4_linear_206_58)"
                      }),
                      _createElementVNode("path", {
                        d: "M76.54 41.718c-3.877-2.3-7.727-4.614-11.55-6.901a1.988 1.988 0 00-2.026-.027c-6.44 3.713-13.013 7.614-19.627 11.475a4.216 4.216 0 00-1.516 1.52 1750.49 1750.49 0 0114.046 8.072c.014 0 .014.014.027.014 7.446-4.305 14.891-8.624 22.19-12.633a4.766 4.766 0 00-1.543-1.52z",
                        fill: "url(#paint5_linear_206_58)"
                      }),
                      _createElementVNode("path", {
                        d: "M38.614 54.39c-.684-.39-1.542.108-1.542.888 0 3.364.013 6.727.067 10.09a2.13 2.13 0 001.046 1.776c1.046.619 2.093 1.224 3.126 1.843 1.355-1.197 2.71-2.395 4.065-3.605-1.395-3.175-2.79-6.35-4.16-9.498a444.25 444.25 0 00-2.602-1.493z",
                        fill: "url(#paint6_linear_206_58)"
                      }),
                      _createElementVNode("path", {
                        d: "M50.702 61.36c-3.152-1.83-6.319-3.673-9.471-5.475 0 4.372.027 8.744.094 13.116 2.991 1.763 5.97 3.538 8.934 5.287a1.01 1.01 0 001.53-.888l-.04-10.25a2.134 2.134 0 00-1.047-1.79z",
                        fill: "url(#paint7_linear_206_58)"
                      }),
                      _createElementVNode("path", {
                        d: "M39.137 59.407c0 .78.013 1.575.013 2.355 1.396 1.291 2.79 2.583 4.173 3.874-.698-1.681-1.396-3.35-2.093-5.018a83.872 83.872 0 00-2.093-1.21z",
                        fill: "url(#paint8_linear_206_58)"
                      }),
                      _createElementVNode("path", {
                        d: "M41.23 60.605c0 .794.014 1.575.014 2.368 1.422 1.642 2.83 3.296 4.253 4.95 1.395 0 2.804 0 4.2-.013 0-.793-.014-1.587-.014-2.38l-8.452-4.925z",
                        fill: "url(#paint9_linear_206_58)"
                      }),
                      _createElementVNode("path", {
                        d: "M39.153 61.763l.04 3.646c.698.403 1.395.82 2.093 1.224.684-.337 1.368-.66 2.04-.996-.699-.888-1.383-1.776-2.08-2.663l-2.093-1.211z",
                        fill: "url(#paint10_linear_206_58)"
                      }),
                      _createElementVNode("path", {
                        d: "M49.695 67.912c-2.804-1.642-5.62-3.296-8.452-4.938l.04 3.66c2.818 1.654 5.635 3.31 8.44 4.964a335.58 335.58 0 01-.028-3.686z",
                        fill: "url(#paint11_linear_206_58)"
                      })
                    ], -1),
                    _createElementVNode("defs", null, [
                      _createElementVNode("linearGradient", {
                        id: "paint0_linear_206_58",
                        x1: "55.5",
                        y1: "82.5",
                        x2: "55.5",
                        y2: "56.667",
                        gradientUnits: "userSpaceOnUse"
                      }, [
                        _createElementVNode("stop", { "stop-color": "#6B36E6" }),
                        _createElementVNode("stop", {
                          offset: "1",
                          "stop-color": "#70AEFF"
                        })
                      ]),
                      _createElementVNode("linearGradient", {
                        id: "paint1_linear_206_58",
                        x1: "38.229",
                        y1: "74.921",
                        x2: "38.229",
                        y2: "43.037",
                        gradientUnits: "userSpaceOnUse"
                      }, [
                        _createElementVNode("stop", { "stop-color": "#6B36E6" }),
                        _createElementVNode("stop", {
                          offset: "1",
                          "stop-color": "#70AEFF"
                        })
                      ]),
                      _createElementVNode("linearGradient", {
                        id: "paint2_linear_206_58",
                        x1: "54.709",
                        y1: "81.712",
                        x2: "54.709",
                        y2: "47.698",
                        gradientUnits: "userSpaceOnUse"
                      }, [
                        _createElementVNode("stop", { "stop-color": "#6B36E6" }),
                        _createElementVNode("stop", {
                          offset: "1",
                          "stop-color": "#70AEFF"
                        })
                      ]),
                      _createElementVNode("linearGradient", {
                        id: "paint3_linear_206_58",
                        x1: "67.308",
                        y1: "81.728",
                        x2: "67.308",
                        y2: "42.943",
                        gradientUnits: "userSpaceOnUse"
                      }, [
                        _createElementVNode("stop", { "stop-color": "#6B36E6" }),
                        _createElementVNode("stop", {
                          offset: "1",
                          "stop-color": "#70AEFF"
                        })
                      ]),
                      _createElementVNode("linearGradient", {
                        id: "paint4_linear_206_58",
                        x1: "49.399",
                        y1: "47.772",
                        x2: "49.399",
                        y2: "30",
                        gradientUnits: "userSpaceOnUse"
                      }, [
                        _createElementVNode("stop", { "stop-color": "#C2D1FF" }),
                        _createElementVNode("stop", {
                          offset: "1",
                          "stop-color": "#EDE9FF"
                        })
                      ]),
                      _createElementVNode("linearGradient", {
                        id: "paint5_linear_206_58",
                        x1: "59.951",
                        y1: "55.871",
                        x2: "59.951",
                        y2: "34.526",
                        gradientUnits: "userSpaceOnUse"
                      }, [
                        _createElementVNode("stop", { "stop-color": "#C2D1FF" }),
                        _createElementVNode("stop", {
                          offset: "1",
                          "stop-color": "#EDE9FF"
                        })
                      ]),
                      _createElementVNode("linearGradient", {
                        id: "paint6_linear_206_58",
                        x1: "41.223",
                        y1: "68.987",
                        x2: "41.223",
                        y2: "54.255",
                        gradientUnits: "userSpaceOnUse"
                      }, [
                        _createElementVNode("stop", { "stop-color": "#C2D1FF" }),
                        _createElementVNode("stop", {
                          offset: "1",
                          "stop-color": "#EDE9FF"
                        })
                      ]),
                      _createElementVNode("linearGradient", {
                        id: "paint7_linear_206_58",
                        x1: "46.51",
                        y1: "74.433",
                        x2: "46.51",
                        y2: "55.885",
                        gradientUnits: "userSpaceOnUse"
                      }, [
                        _createElementVNode("stop", { "stop-color": "#C2D1FF" }),
                        _createElementVNode("stop", {
                          offset: "1",
                          "stop-color": "#EDE9FF"
                        })
                      ]),
                      _createElementVNode("linearGradient", {
                        id: "paint8_linear_206_58",
                        x1: "41.23",
                        y1: "65.636",
                        x2: "41.23",
                        y2: "59.407",
                        gradientUnits: "userSpaceOnUse"
                      }, [
                        _createElementVNode("stop", { "stop-color": "#6B36E6" }),
                        _createElementVNode("stop", {
                          offset: "1",
                          "stop-color": "#70AEFF"
                        })
                      ]),
                      _createElementVNode("linearGradient", {
                        id: "paint9_linear_206_58",
                        x1: "45.463",
                        y1: "67.924",
                        x2: "45.463",
                        y2: "60.605",
                        gradientUnits: "userSpaceOnUse"
                      }, [
                        _createElementVNode("stop", { "stop-color": "#6B36E6" }),
                        _createElementVNode("stop", {
                          offset: "1",
                          "stop-color": "#70AEFF"
                        })
                      ]),
                      _createElementVNode("linearGradient", {
                        id: "paint10_linear_206_58",
                        x1: "41.239",
                        y1: "66.633",
                        x2: "41.239",
                        y2: "61.763",
                        gradientUnits: "userSpaceOnUse"
                      }, [
                        _createElementVNode("stop", { "stop-color": "#C2D1FF" }),
                        _createElementVNode("stop", {
                          offset: "1",
                          "stop-color": "#EDE9FF"
                        })
                      ]),
                      _createElementVNode("linearGradient", {
                        id: "paint11_linear_206_58",
                        x1: "45.483",
                        y1: "71.598",
                        x2: "45.483",
                        y2: "62.974",
                        gradientUnits: "userSpaceOnUse"
                      }, [
                        _createElementVNode("stop", { "stop-color": "#C2D1FF" }),
                        _createElementVNode("stop", {
                          offset: "1",
                          "stop-color": "#EDE9FF"
                        })
                      ]),
                      _createElementVNode("clipPath", { id: "clip0_206_58" }, [
                        _createElementVNode("path", {
                          fill: "#fff",
                          d: "M0 0h111v111H0z"
                        })
                      ]),
                      _createElementVNode("filter", {
                        id: "filter0_f_206_58",
                        x: "24",
                        y: "42.667",
                        width: "63",
                        height: "53.833",
                        filterUnits: "userSpaceOnUse",
                        "color-interpolation-filters": "sRGB"
                      }, [
                        _createElementVNode("feFlood", {
                          "flood-opacity": "0",
                          result: "BackgroundImageFix"
                        }),
                        _createElementVNode("feBlend", {
                          in: "SourceGraphic",
                          in2: "BackgroundImageFix",
                          result: "shape"
                        }),
                        _createElementVNode("feGaussianBlur", {
                          stdDeviation: "7",
                          result: "effect1_foregroundBlur_206_58"
                        })
                      ])
                    ], -1)
                  ])))
                ]),
                _: 1
              }, 8, ["title", "description"])
            ])
          ])
        ]),
        _: 1
      }, 8, ["title"]),
      _createVNode(_component_heading_section_template, {
        title: _ctx.tp(`storingCards.${ _ctx.serviceType }.title`, _ctx.params),
        "body-class": "col-12 col-md-11 col-lg-9 col-xl-7",
        "margin-bottom": "",
        "margin-top": ""
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("div", _hoisted_11, [
              _createElementVNode("div", _hoisted_12, [
                _createElementVNode("div", _hoisted_13, [
                  _createVNode(_component_storing_card, {
                    entries: _ctx.items,
                    class: _normalizeClass({'q-mt-lg': _ctx.$q.screen.xs}),
                    "picture-right": "",
                    folder: "home-all-page"
                  }, null, 8, ["entries", "class"])
                ])
              ])
            ])
          ])
        ]),
        _: 1
      }, 8, ["title"]),
      (_ctx.top5Companies.length)
        ? (_openBlock(), _createBlock(_component_heading_section_template, {
            key: 0,
            title: _ctx.companiesTitle,
            "margin-bottom": "",
            "margin-top": "",
            "body-class": "col-12 col-sm-10 col-md-8 col-lg-7"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_14, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.top5Companies, (c, i) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: c.id,
                    class: _normalizeClass([{'q-pt-md': i > 0 && _ctx.$q.screen.xs, 'q-pa-md': _ctx.$q.screen.gt.xs}, "col-12 col-sm-8 col-md-6 col-xl-4"])
                  }, [
                    _createVNode(_component_company_card, {
                      company: c,
                      dense: _ctx.$q.screen.xs,
                      "charge-type": _ctx.chargeType
                    }, {
                      after: _withCtx(() => [
                        _createVNode(_component_closest3_places, {
                          class: "q-px-md q-pb-md text-small",
                          coordinates: c.coordinates,
                          attractions: c.attractions
                        }, null, 8, ["coordinates", "attractions"])
                      ]),
                      _: 2
                    }, 1032, ["company", "dense", "charge-type"])
                  ], 2))
                }), 128))
              ]),
              _createElementVNode("div", _hoisted_15, [
                _createElementVNode("div", _hoisted_16, [
                  _createVNode(_component_q_btn, {
                    label: _ctx.t('action.showMore'),
                    to: _ctx.to,
                    class: "text-transform-none",
                    color: "primary",
                    flat: "",
                    rel: "nofollow"
                  }, null, 8, ["label", "to"])
                ])
              ])
            ]),
            _: 1
          }, 8, ["title"]))
        : _createCommentVNode("", true),
      (_ctx.reviews.length)
        ? (_openBlock(), _createBlock(_component_heading_section_template, {
            key: 1,
            title: _ctx.tp(`recommend.${ _ctx.serviceType }`, _ctx.params),
            "margin-bottom": "",
            "no-side-padding": ""
          }, {
            default: _withCtx(() => [
              _createVNode(_component_qeepl_rating, {
                dense: _ctx.$q.screen.xs,
                class: "q-mb-lg q-pb-md",
                fill: ""
              }, null, 8, ["dense"]),
              _createVNode(_component_responsive_preview_template, {
                items: _ctx.reviews,
                "col-class": "q-py-md"
              }, {
                default: _withCtx((slotProps) => [
                  _createVNode(_component_review_card, {
                    review: slotProps.item
                  }, null, 8, ["review"])
                ]),
                _: 1
              }, 8, ["items"]),
              _createElementVNode("div", _hoisted_17, [
                _createElementVNode("div", _hoisted_18, [
                  _createVNode(_component_q_btn, {
                    label: _ctx.t('action.showMore'),
                    to: _ctx.toReviews(),
                    class: "text-transform-none",
                    color: "primary",
                    flat: ""
                  }, null, 8, ["label", "to"])
                ])
              ])
            ]),
            _: 1
          }, 8, ["title"]))
        : _createCommentVNode("", true),
      _createVNode(_component_heading_section_template, {
        title: _ctx.t(`price-banner.${ _ctx.serviceType }.price`, _ctx.params),
        "body-class": `col-12 col-md-8 col-lg-12 col-xl-10 row justify-center${ _ctx.$q.screen.gt.xs ? ' q-col-gutter-xl' : ''}`,
        "margin-bottom": ""
      }, {
        default: _withCtx(() => [
          (_ctx.serviceType === 'DEFAULT')
            ? (_openBlock(), _createBlock(_component_price_banner, {
                key: 0,
                class: _normalizeClass([{'q-mb-xl': _ctx.$q.screen.lt.lg && _ctx.type === 'ALL'}, "col-12 col-lg-6"]),
                query: _ctx.urlQuery || 'type=DEFAULT',
                params: _ctx.params,
                alt: "Price info for short-term storage",
                type: "DEFAULT"
              }, null, 8, ["class", "query", "params"]))
            : _createCommentVNode("", true),
          (_ctx.serviceType === 'SELF')
            ? (_openBlock(), _createBlock(_component_price_banner, {
                key: 1,
                query: _ctx.urlQuery,
                params: _ctx.params,
                class: "col-12 col-lg-6",
                alt: "Price info for long-term storage",
                type: "SELF"
              }, null, 8, ["query", "params"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["title", "body-class"]),
      _createVNode(_component_heading_section_template, {
        title: _ctx.tp(`ourValues.${ _ctx.serviceType }.title`, _ctx.params),
        "margin-top": "",
        "margin-bottom": ""
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_19, [
            _createElementVNode("div", {
              class: _normalizeClass(["col-12 col-md-6 col-xl-4 q-py-md", {'q-px-md': _ctx.$q.screen.gt.sm}])
            }, [
              _createVNode(_component_home_values_card, {
                title: _ctx.tp(`ourValues.${ _ctx.serviceType }.secure.title`, _ctx.params),
                description: _ctx.tp(`ourValues.${ _ctx.serviceType }.secure.description`, _ctx.params),
                src: "safe.png",
                width: "132",
                height: "132"
              }, null, 8, ["title", "description"])
            ], 2),
            _createElementVNode("div", {
              class: _normalizeClass(["col-12 col-md-6 col-xl-4 q-py-md", {'q-px-md': _ctx.$q.screen.gt.sm}])
            }, [
              _createVNode(_component_home_values_card, {
                title: _ctx.tp(`ourValues.${ _ctx.serviceType }.simple.title`, _ctx.params),
                description: _ctx.tp(`ourValues.${ _ctx.serviceType }.simple.description`, _ctx.params),
                src: "phone.png",
                width: "98",
                height: "132"
              }, null, 8, ["title", "description"])
            ], 2),
            _createElementVNode("div", {
              class: _normalizeClass(["col-12 col-md-6 col-xl-4 q-py-md", {'q-px-md': _ctx.$q.screen.gt.sm, 'q-pb-xl': _ctx.$q.screen.sm}])
            }, [
              _createVNode(_component_home_values_card, {
                title: _ctx.tp(`ourValues.${ _ctx.serviceType }.profitable.title`, _ctx.params),
                description: _ctx.tp(`ourValues.${ _ctx.serviceType }.profitable.description`, _ctx.params),
                src: "card.png",
                width: "112",
                height: "88"
              }, null, 8, ["title", "description"])
            ], 2)
          ])
        ]),
        _: 1
      }, 8, ["title"]),
      _renderSlot(_ctx.$slots, "pre-faq"),
      _createVNode(_component_faq, {
        entries: _ctx.faqEntries,
        description: _ctx.tp(`faqDescription.${ _ctx.serviceType }`, _ctx.params)
      }, null, 8, ["entries", "description"]),
      _renderSlot(_ctx.$slots, "post-faq"),
      _createVNode(_component_q_no_ssr, null, {
        default: _withCtx(() => [
          (_ctx.language === 'ru')
            ? (_openBlock(), _createBlock(_component_heading_section_template, {
                key: 0,
                title: _ctx.tp('aboutUs', _ctx.params),
                "margin-bottom": "",
                "margin-top": ""
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_articles)
                ]),
                _: 1
              }, 8, ["title"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }),
      _renderSlot(_ctx.$slots, "post-articles"),
      _createVNode(_component_app_links_banner, { alt: "App links" }),
      _renderSlot(_ctx.$slots, "post-app-links"),
      _createVNode(_component_q_no_ssr, null, {
        default: _withCtx(() => [
          (_ctx.shown && _ctx.$q.screen.xs)
            ? (_openBlock(), _createBlock(_component_app_top_banner, { key: 0 }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _: 2
  }, [
    (_ctx.$slots.breadcrumbs)
      ? {
          name: "breadcrumbs",
          fn: _withCtx(() => [
            _renderSlot(_ctx.$slots, "breadcrumbs")
          ]),
          key: "0"
        }
      : undefined
  ]), 1032, ["description", "i18n", "title"]))
}