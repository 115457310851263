import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "row faq" }
const _hoisted_2 = { class: "col-12 text-center q-pb-lg" }
const _hoisted_3 = {
  key: 0,
  class: "col-12 q-pb-lg text-center"
}
const _hoisted_4 = { class: "col-12 row items-center justify-center q-pb-xl" }
const _hoisted_5 = { class: "col-12 col-md-8 col-lg-5 text-left q-px-md" }
const _hoisted_6 = { class: "text-large text-weight-bolder" }
const _hoisted_7 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_item_label = _resolveComponent("q-item-label")!
  const _component_q_item_section = _resolveComponent("q-item-section")!
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_q_card = _resolveComponent("q-card")!
  const _component_q_expansion_item = _resolveComponent("q-expansion-item")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.tp('title')), 1),
    (_ctx.description)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.description), 1))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.entries, (f, i) => {
          return (_openBlock(), _createBlock(_component_q_expansion_item, {
            ref_for: true,
            ref: "itemsRef",
            key: i,
            "expand-icon": _ctx.matKeyboardArrowDown,
            "expanded-icon": _ctx.matKeyboardArrowUp,
            "default-opened": "",
            class: "rounded-borders overflow-hidden q-mb-sm"
          }, {
            header: _withCtx(() => [
              _createVNode(_component_q_item_section, null, {
                default: _withCtx(() => [
                  _createVNode(_component_q_item_label, null, {
                    default: _withCtx(() => [
                      _createElementVNode("h3", _hoisted_6, _toDisplayString(f.question), 1)
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 2
              }, 1024)
            ]),
            default: _withCtx(() => [
              _createVNode(_component_q_card, null, {
                default: _withCtx(() => [
                  _createVNode(_component_q_card_section, null, {
                    default: _withCtx(() => [
                      _createElementVNode("div", {
                        class: "text-normal",
                        innerHTML: f.answer
                      }, null, 8, _hoisted_7)
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 2
              }, 1024)
            ]),
            _: 2
          }, 1032, ["expand-icon", "expanded-icon"]))
        }), 128))
      ])
    ])
  ]))
}