import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "closest-3-places" }
const _hoisted_2 = {
  key: "p",
  class: "q-py-xs"
}
const _hoisted_3 = { class: "row items-center justify-between" }
const _hoisted_4 = { class: "col-auto" }
const _hoisted_5 = { class: "col-auto" }
const _hoisted_6 = { class: "row items-center" }
const _hoisted_7 = { class: "col-auto" }
const _hoisted_8 = { class: "col-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_icon = _resolveComponent("q-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.closest3Places, (p) => {
      return (_openBlock(), _createElementBlock("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, _toDisplayString(p.name), 1),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _createVNode(_component_q_icon, {
                  name: _ctx.matDirectionsWalk,
                  size: "xs",
                  class: "q-pr-sm q-pb-xs",
                  color: "grey-7"
                }, null, 8, ["name"])
              ]),
              _createElementVNode("div", _hoisted_8, _toDisplayString(p.away), 1)
            ])
          ])
        ])
      ]))
    }), 128))
  ]))
}