import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "row items-center justify-center q-px-md q-pb-xl" }
const _hoisted_2 = { class: "col-12 col-md-10 col-lg-7" }
const _hoisted_3 = { class: "col-4 col-sm-2 text-weight-bold" }
const _hoisted_4 = { class: "col-8 col-sm-10" }
const _hoisted_5 = { class: "row items-center" }
const _hoisted_6 = {
  key: 0,
  class: "text-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_q_btn = _resolveComponent("q-btn")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.countries, (country) => {
        return (_openBlock(), _createElementBlock(_Fragment, null, [
          (_ctx.countryCities[country.key]?.length)
            ? (_openBlock(), _createElementBlock("div", {
                key: country.key,
                class: "row items-center q-pt-md"
              }, [
                _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.st(country.name)), 1),
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("div", _hoisted_5, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.countryCities[country.key], (city) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: city.key,
                        class: "col-auto q-pa-sm"
                      }, [
                        _createVNode(_component_router_link, {
                          to: _ctx.toCity(city.key),
                          class: "text-grey-8 text-underline"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.st(city.name)), 1)
                          ]),
                          _: 2
                        }, 1032, ["to"])
                      ]))
                    }), 128))
                  ])
                ])
              ]))
            : _createCommentVNode("", true)
        ], 64))
      }), 256)),
      (_ctx.alias === 'ALL')
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
            _createVNode(_component_q_btn, {
              to: _ctx.toRootLuggage(),
              label: _ctx.t('action.showMore'),
              color: "primary",
              "no-caps": "",
              rounded: "",
              unelevated: "",
              flat: ""
            }, null, 8, ["to", "label"])
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}