import { ref } from 'vue'
import { defineStore } from 'pinia'
import useAnonymousApi from 'src/api/anonymous.api'
import { Attraction } from 'src/model/attraction.model'
import { Content } from 'src/model/content.model'
import { ServiceTypeAlias } from 'src/model/common.model'

const useCityAndAttractionHome = defineStore('cityAndAttractionHome', () => {
  const api = useAnonymousApi()

  const content = ref<Content | null>(null)
  const attractions = ref<Attraction[]>([])

  const onContentLoaded = (contentResult: Content | null) => {
    content.value = contentResult
  }
  const onAttractionsLoaded = (attractionsResult: Attraction[]) => {
    attractions.value = attractionsResult
  }
  const loadAllByCity = async (cityKey: string, serviceTypeAlias: ServiceTypeAlias) => {
    const [contentResult, attractionsResult] = await Promise.all([
      api.getCityContent(cityKey, serviceTypeAlias, res => onContentLoaded(res.data)).catch(() => null),
      api.getAttractions(cityKey, res => onAttractionsLoaded(res.data))
    ])
    onContentLoaded(contentResult || null)
    onAttractionsLoaded(attractionsResult)
  }
  const loadAllByAttraction = async (cityKey: string, attractionKey: string, serviceTypeAlias: ServiceTypeAlias) => {
    const [contentResult, attractionsResult] = await Promise.all([
      api.getAttractionContent(attractionKey, serviceTypeAlias, res => onContentLoaded(res.data)).catch(() => null),
      api.getAttractions(cityKey, res => onAttractionsLoaded(res.data))
    ])
    onContentLoaded(contentResult || null)
    onAttractionsLoaded(attractionsResult)
  }

  return {
    content,
    attractions,
    loadAllByCity,
    loadAllByAttraction
  }
})

export default useCityAndAttractionHome
