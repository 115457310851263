
import { computed, defineComponent, onMounted, ref } from 'vue'
import { languageToLabel, useI18n } from 'boot/i18n'
import { Language } from 'src/model/language.model'
import { QIcon, QItem, QItemLabel, QItemSection, QSelect } from 'quasar'
import useSelect from 'src/compositions/select'
import { refStore } from 'stores/__common'
import useUserDetails from 'stores/userDetails'
import { RouteLocationRaw, useRouter } from 'vue-router'

const name = 'language-selector'

interface Option {
  label: string
  value: Language
}

function initOptions(allowedLanguages: Language[]): Option[] {
  return allowedLanguages
    .map((language: Language) => ({
      label: languageToLabel[language],
      value: language
    }))
}

export default defineComponent({
  name,
  components: {
    QSelect,
    QItem,
    QItemSection,
    QItemLabel,
    QIcon
  },
  props: {},
  emits: ['change'],
  setup(props, { emit }) {
    const { currentRoute } = useRouter()
    const { isAnonymous, updateLanguage } = refStore(useUserDetails())
    const { language, setLanguage: setLanguageI18n, allowedLanguages } = useI18n()
    const { options, filterOptions } = useSelect<Option>({
      initOptions: () => Promise.resolve(initialOptions.value)
    })

    const initialOptions = computed(() => initOptions(allowedLanguages))

    const label = computed(() => languageToLabel[language.value])

    const setLanguage = (language: Language) => {
      return setLanguageI18n(language)
        .then(() => emit('change', language))
        .then(() => isAnonymous.value ? Promise.resolve() : updateLanguage(language))
    }

    const toPage = (newLanguage: Language): RouteLocationRaw | undefined => {
      if (newLanguage === language.value) {
        return undefined
      }
      return {
        path: currentRoute.value.fullPath.replace(`/${ language.value }`, `/${ newLanguage }`),
        query: undefined
      }
    }

    return {
      options,
      language,
      label,
      initialOptions,
      setLanguage,
      filterOptions,
      toPage
    }
  }
})
