
import { computed, defineComponent, ref } from 'vue'
import PageTemplate from 'components/template/page-template.vue'
import LargeBtn from 'components/simple/large-btn.vue'
import LoginInputCheck from 'components/advanced/login-input-check.vue'
import ServiceConditions from 'components/simple/service-conditions.vue'
import { useI18n$ } from 'boot/i18n'
import { QBtn, QCard, QCardActions, QCardSection, QInput, QSeparator } from 'quasar'
import useValidation from 'src/compositions/validation/validation'
import useRoutes from 'src/compositions/routes'
import { useRouter } from 'vue-router'
import useAuthorization from 'src/compositions/authorization'
import useUserDetails from 'stores/userDetails'
import { refStore } from 'stores/__common'
import useEnvironment from 'src/compositions/environment'
import useValidationRules from 'src/compositions/validation/validationRules'
import Breadcrumbs from 'components/simple/breadcrumbs.vue'
import usePageExtensions from 'src/compositions/pageExtensions'
import useAnalytics from 'src/compositions/analytics'
import basePrefetch from 'src/compositions/prefetch/basePrefetch'
import FirebaseAuth from 'components/advanced/firebase-auth.vue'

const name = 'login'

export default defineComponent({
  name,
  components: {
    QBtn,
    QCard,
    QCardSection,
    QCardActions,
    QInput,
    QSeparator,
    FirebaseAuth,
    PageTemplate,
    LargeBtn,
    LoginInputCheck,
    ServiceConditions,
    Breadcrumbs
  },
  async preFetch(options) {
    await basePrefetch(options)
  },
  setup() {
    const { app } = useEnvironment()
    const {
      toPartners,
      toRecovery
    } = useRoutes()
    const { currentRoute } = useRouter()
    const {
      authorize: doAuthorize,
      authorizing,
      saveReturnUrl
    } = useAuthorization()
    const { user, blocked: userBlocked } = refStore(useUserDetails())
    const {
      tp,
      t
    } = useI18n$(name)
    const { required } = useValidationRules()
    const { logEvent } = useAnalytics()

    const loginInputCheckRef = ref() as unknown as typeof LoginInputCheck
    const otpEnter = ref(!('partner' in currentRoute.value.query))
    const userEmail = ref('')
    const userPassword = ref('')

    const otpInputValid = computed(() => loginInputCheckRef.value?.valid)
    const checkSuccess = computed(() => loginInputCheckRef.value?.checkSuccess)

    const {
      valid: emailFieldsValid,
      validations
    } = useValidation({
      userEmail: {
        rules: [required],
        ref: userEmail
      },
      userPassword: {
        rules: [required],
        ref: userPassword
      }
    })

    const authorizeByOtp = () => loginInputCheckRef.value?.startCheck()
    const authorizeByEmailAndPassword = () => {
      return doAuthorize(userEmail.value, userPassword.value)
        .then(res => {
          res && logEvent({
            name: 'sign_up',
            params: {
              method: 'Email'
            }
          })
        })
    }
    const enterByOtp = () => otpEnter.value = true
    const enterByEmailAndPassword = () => otpEnter.value = false

    if ('returnURL' in currentRoute.value.query) {
      saveReturnUrl(currentRoute.value.query.returnURL as string)
    }

    return {
      phoneInputCheckRef: loginInputCheckRef,
      app,
      otpEnter,
      user,
      userBlocked,
      userEmail,
      userPassword,
      processing: authorizing,
      otpInputValid,
      checkSuccess,
      emailFieldsValid,
      validations,
      toPartners: computed(() => toPartners(true)),
      toRecovery: computed(() => toRecovery()),
      tp,
      t,
      authorizeByOtp,
      authorizeByEmailAndPassword,
      enterByOtp,
      enterByEmailAndPassword,
      ...usePageExtensions({
        page: name,
        breadcrumbs: () => ([
          { path: 'login', label: t('global.pages.login') }
        ])
      })
    }
  }
})
