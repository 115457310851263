import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createSlots as _createSlots } from "vue"

const _hoisted_1 = { class: "row items-center justify-center q-px-sm" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_breadcrumbs = _resolveComponent("breadcrumbs")!
  const _component_countries_list = _resolveComponent("countries-list")!
  const _component_page_template = _resolveComponent("page-template")!
  const _component_object_card = _resolveComponent("object-card")!
  const _component_heading_section_template = _resolveComponent("heading-section-template")!
  const _component_home_page_template = _resolveComponent("home-page-template")!

  return (_ctx.alias === 'LUGGAGE')
    ? (_openBlock(), _createBlock(_component_page_template, {
        key: 0,
        title: _ctx.tp('title'),
        column: "",
        position: "start"
      }, {
        breadcrumbs: _withCtx(() => [
          _createVNode(_component_breadcrumbs, { values: _ctx.breadcrumbEntries }, null, 8, ["values"])
        ]),
        default: _withCtx(() => [
          _createVNode(_component_countries_list, {
            alias: _ctx.alias,
            name: _ctx.name
          }, null, 8, ["alias", "name"])
        ]),
        _: 1
      }, 8, ["title"]))
    : (_openBlock(), _createBlock(_component_home_page_template, {
        key: 1,
        companies: _ctx.companies,
        "companies-title": _ctx.tp('companiesTitle'),
        description: _ctx.tp('description', _ctx.params),
        "faq-entries": _ctx.faqEntries,
        title: _ctx.tp('title', _ctx.params),
        type: _ctx.type
      }, _createSlots({
        "after-preview": _withCtx(() => [
          _renderSlot(_ctx.$slots, "default", {}, () => [
            _createElementVNode("div", _hoisted_1, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.main5Cities, (c, i) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: c.key,
                  class: _normalizeClass([{'gt-sm': i === 4}, "col-6 col-sm-3 col-md-2 q-pa-sm"])
                }, [
                  _createVNode(_component_object_card, {
                    src: c.photoURL,
                    title: _ctx.st(c.name),
                    to: _ctx.toCity(c),
                    "fallback-src": "/images/city-fallback.jpg"
                  }, null, 8, ["src", "title", "to"])
                ], 2))
              }), 128))
            ])
          ])
        ]),
        "before-how-to": _withCtx(() => [
          _renderSlot(_ctx.$slots, "before-how-to")
        ]),
        "pre-faq": _withCtx(() => [
          _renderSlot(_ctx.$slots, "pre-faq")
        ]),
        "post-articles": _withCtx(() => [
          _createVNode(_component_heading_section_template, {
            title: _ctx.tp('countriesListTitle'),
            "margin-bottom": ""
          }, {
            default: _withCtx(() => [
              _createVNode(_component_countries_list, {
                alias: _ctx.alias,
                name: _ctx.name,
                "with-priority": _ctx.alias === 'ALL'
              }, null, 8, ["alias", "name", "with-priority"])
            ]),
            _: 1
          }, 8, ["title"])
        ]),
        _: 2
      }, [
        (_ctx.breadcrumbEntries.length && _ctx.alias !== 'ALL')
          ? {
              name: "breadcrumbs",
              fn: _withCtx(() => [
                _createVNode(_component_breadcrumbs, { values: _ctx.breadcrumbEntries }, null, 8, ["values"])
              ]),
              key: "0"
            }
          : undefined
      ]), 1032, ["companies", "companies-title", "description", "faq-entries", "title", "type"]))
}