
import { defineComponent, onMounted, ref } from 'vue'
import { QVirtualScroll } from 'quasar'

const name = 'responsive-preview-template'

export default defineComponent({
  name,
  components: {
    QVirtualScroll
  },
  props: {
    items: {
      type: Array,
      required: true
    },
    colClass: {
      type: String,
      default: 'col-auto'
    },
    xsContainerClass: {
      type: String,
      default: 'q-py-md q-px-sm'
    },
  },
  setup() {
    const virtualScrollRef = ref<InstanceType<typeof QVirtualScroll> | null>(null)
    const mounted = ref(false)

    onMounted(() => {
      mounted.value = true
    })

    const moveTo = (index: number) => {
      virtualScrollRef.value?.scrollTo(index, 'center-force')
    }

    return {
      virtualScrollRef,
      mounted,
      moveTo
    }
  }
})
