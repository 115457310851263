import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_city_template = _resolveComponent("city-template")!

  return (_openBlock(), _createBlock(_component_city_template, {
    key: _ctx.path,
    name: _ctx.name,
    alias: _ctx.alias
  }, null, 8, ["name", "alias"]))
}