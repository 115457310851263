
import { computed, defineComponent } from 'vue'
import { RouteLocationRaw, useRouter } from 'vue-router'
import { QPagination, QResponsive, QSkeleton } from 'quasar'
import { useI18n$ } from 'boot/i18n'
import PageTemplate from 'components/template/page-template.vue'
import BlogPreview from 'components/simple/blog-preview.vue'
import Breadcrumbs from 'components/simple/breadcrumbs.vue'
import blogListPrefetch from 'src/compositions/prefetch/blogListPrefetch'
import usePageExtensions from 'src/compositions/pageExtensions'
import useBlogListHome from 'stores/blogListHome'
import { refStore } from 'stores/__common'
import { BLOG_LIST } from 'pages/names'

const name = BLOG_LIST

export default defineComponent({
  name,
  components: {
    QPagination,
    QSkeleton,
    QResponsive,
    PageTemplate,
    BlogPreview,
    Breadcrumbs
  },
  async preFetch(options) {
    await blogListPrefetch(options)
  },
  setup() {
    const { pagesCount, page, loading } = refStore(useBlogListHome())
    const router = useRouter()
    const { tp, t } = useI18n$(name)

    const pageNumber = computed(() => page.value.page!)
    const list = computed(() => page.value.data)

    const toPage = (page: number): RouteLocationRaw => {
      const currentPage = Number(router.currentRoute.value.query?.page) || 1
      if (currentPage === page) return ''
      if (page <= 1) return { replace: true }
      return { query: { page }, replace: true }
    }

    return {
      pagesCount,
      pageNumber,
      list,
      loading,
      toPage,
      t,
      tp,
      ...usePageExtensions({
        page: name,
        breadcrumbs: () => ([
          { path: 'blog', label: t('global.pages.blog') }
        ]),
        excludeAlternateQuery: true
      })
    }
  }
})
