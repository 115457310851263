import { useQuasar } from 'quasar'

export const matKeyboardArrowDown = 'M0 0h24v24H0V0z@@fill:none;&&M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z'
export const matKeyboardArrowUp = 'M0 0h24v24H0z@@fill:none;&&M7.41 15.41L12 10.83l4.59 4.58L18 14l-6-6-6 6z'
export const matClose = 'M0 0h24v24H0z@@fill:none;&&M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z'
export const matArrowBack = 'M0 0h24v24H0z@@fill:none;&&M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z'
export const matError = 'M0 0h24v24H0z@@fill:none;&&M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z'
export const matErrorOutline = 'M0 0h24v24H0V0z@@fill:none;&&M11 15h2v2h-2zm0-8h2v6h-2zm.99-5C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z'
export const matArrowDropDown = 'M0 0h24v24H0z@@fill:none;&&M7 10l5 5 5-5z'
export const matRefresh = 'M0 0h24v24H0z@@fill:none;&&M17.65 6.35C16.2 4.9 14.21 4 12 4c-4.42 0-7.99 3.58-7.99 8s3.57 8 7.99 8c3.73 0 6.84-2.55 7.73-6h-2.08c-.82 2.33-3.04 4-5.65 4-3.31 0-6-2.69-6-6s2.69-6 6-6c1.66 0 3.14.69 4.22 1.78L13 11h7V4l-2.35 2.35z'
export const matArrowLeft = 'M14 7l-5 5 5 5V7z&&M24 0v24H0V0h24z@@fill:none;'
export const matArrowRight = 'M10 17l5-5-5-5v10z&&M0 24V0h24v24H0z@@fill:none;'
export const matChevronLeft = 'M0 0h24v24H0z@@fill:none;&&M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z'
export const matChevronRight = 'M0 0h24v24H0z@@fill:none;&&M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z'
export const matLens = 'M0 0h24v24H0z@@fill:none;&&M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2z'
export const matCheck = 'M0 0h24v24H0z@@fill:none;&&M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z'
export const matEdit = 'M0 0h24v24H0z@@fill:none;&&M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z'
export const matContentCopy = 'M0 0h24v24H0z@@fill:none;&&M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z'
export const matInfo = 'M0 0h24v24H0z@@fill:none;&&M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z'

export function useIcons () {
  const { iconSet } = useQuasar()

  const setup = () => {
    iconSet.field.error = matError
    iconSet.field.clear = matClose
    iconSet.arrow.dropdown = matArrowDropDown
    iconSet.chip.remove = matClose
    iconSet.datetime.arrowLeft = matArrowLeft
    iconSet.datetime.arrowRight = matArrowRight
    iconSet.tabs.left = matChevronLeft
    iconSet.tabs.right = matArrowRight
    iconSet.carousel.left = matChevronLeft
    iconSet.carousel.right = matChevronRight
    iconSet.carousel.navigationIcon = matLens
  }

  return {
    setup
  }
}
