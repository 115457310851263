import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_index_template = _resolveComponent("index-template")!

  return (_openBlock(), _createBlock(_component_index_template, {
    key: _ctx.alias,
    name: _ctx.name,
    alias: _ctx.alias
  }, null, 8, ["name", "alias"]))
}