import { PreFetchOptions } from '@quasar/app-webpack'
import useUserPreview from 'stores/userPreview'
import useAppData from 'stores/appData'
import { isPath, pathToKey } from 'src/functions/utils'
import useUserDetails from 'stores/userDetails'
import useUserLocation, { LocationType } from 'stores/userLocation'
import { ServiceTypeAlias, ServiceTypeAliases } from 'src/model/common.model'
import { handleNotFound } from 'src/compositions/prefetch/common/errors'
import useCityAndAttractionHome from 'stores/cityAndAttractionHome'

export default async function attractionPrefetch(options: PreFetchOptions<any>, serviceTypeAlias?: ServiceTypeAlias) {
  const detailsState = useUserDetails(options.store)
  const previewState = useUserPreview(options.store)
  const cityAndAttractionHome = useCityAndAttractionHome(options.store)
  const appDataState = useAppData(options.store)
  const locationState = useUserLocation(options.store)

  const path = options.currentRoute.params.serviceTypeAlias as string
  const cityPath = options.currentRoute.params.city as string
  const attractionPath = options.currentRoute.params.attraction as string
  const attraction = pathToKey(attractionPath)
  const city = pathToKey(cityPath)
  const key = pathToKey(path) as ServiceTypeAlias
  serviceTypeAlias = serviceTypeAlias || key

  if (
    !ServiceTypeAliases.includes(serviceTypeAlias) ||
    ['LUGGAGE', 'SELF'].includes(key) ||
    !(path ? isPath(path) : true) ||
    !isPath(cityPath) ||
    !isPath(attractionPath)
  ) {
    handleNotFound(options)
    return
  }

  await Promise.all([
    previewState.loadAllByAttraction(attraction),
    cityAndAttractionHome.loadAllByAttraction(city, attraction, serviceTypeAlias),
    appDataState.loadReviews(detailsState.language),
    appDataState.loadBagsCount(),
  ])
  if (previewState.city?.key !== city || !previewState.attraction?.services.includes(serviceTypeAlias)) {
    handleNotFound(options)
    return
  }
  locationState.setLocation({ point: previewState.attraction.center }, LocationType.GEO)
}
