import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_city_and_attraction_template = _resolveComponent("city-and-attraction-template")!

  return (_openBlock(), _createBlock(_component_city_and_attraction_template, {
    name: _ctx.name,
    alias: "LUGGAGE",
    attraction: ""
  }, null, 8, ["name"]))
}