import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, mergeProps as _mergeProps } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_item_label = _resolveComponent("q-item-label")!
  const _component_q_item_section = _resolveComponent("q-item-section")!
  const _component_q_item = _resolveComponent("q-item")!
  const _component_q_select = _resolveComponent("q-select")!

  return (_openBlock(), _createBlock(_component_q_select, {
    "display-value": _ctx.label,
    "model-value": _ctx.language,
    options: _ctx.options,
    borderless: "",
    class: "language-selector",
    dense: "",
    "emit-value": "",
    "map-options": "",
    onFilter: _ctx.filterOptions,
    "onUpdate:modelValue": _ctx.setLanguage
  }, {
    append: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.initialOptions, (o) => {
        return (_openBlock(), _createBlock(_component_q_item, {
          key: o.value,
          to: _ctx.toPage(o.value),
          class: "text-small q-pa-none hidden"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_q_item_section, { "no-wrap": "" }, {
              default: _withCtx(() => [
                _createVNode(_component_q_item_label, null, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(o.label), 1)
                  ]),
                  _: 2
                }, 1024)
              ]),
              _: 2
            }, 1024)
          ]),
          _: 2
        }, 1032, ["to"]))
      }), 128))
    ]),
    option: _withCtx(({ itemProps, opt }) => [
      _createVNode(_component_q_item, _mergeProps(itemProps, {
        to: _ctx.toPage(opt.value)
      }), {
        default: _withCtx(() => [
          _createVNode(_component_q_item_section, null, {
            default: _withCtx(() => [
              _createVNode(_component_q_item_label, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(opt.label), 1)
                ]),
                _: 2
              }, 1024)
            ]),
            _: 2
          }, 1024)
        ]),
        _: 2
      }, 1040, ["to"])
    ]),
    _: 1
  }, 8, ["display-value", "model-value", "options", "onFilter", "onUpdate:modelValue"]))
}